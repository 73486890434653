import { Component } from 'react';
import axios from 'axios';
import * as Settings from './Settings';
import cookie from 'react-cookies';

var loggedIn = require('./util.js').loggedIn;

// if (loggedIn()){
//     axios.defaults.headers.common['Authorization'] = "Token " + localStorage.getItem('token');
// }
/*
This component will fetch data from a given url
Need to add a render function to use
Props:
data_url, the url of the api, no need to add host and format=json
data_host, the host of the api, default to Settings.Host
Data:
data, loading
*/

class FetchData extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: {results:[]},
            loading: true
        };
    }

    componentDidMount(){
        var Host = Settings.Host;
        // if (this.props.data_host){
        //     Host = this.props.data_host;
        // }
        if (this.props.data_url){
            if (this.props.data_url.indexOf("?") !== -1){
                var url = Host + this.props.data_url + "&format=json"
            }
            else{
                url = Host + this.props.data_url + "?format=json"
            }
            if (url.indexOf("limit=") === -1){
                url = url + "&limit=1000";
            }
            if (loggedIn()){
                axios.get(url,{
                    headers: {
                        "Authorization":"Token " + cookie.load('token',{ path: '/' })
                    }
                })
                    .then(res =>{
                        const data = res.data;
                        this.setState({ data });
                        this.setState({ loading: false });
                })
                    .catch(res =>{
                        alert("Error: "+ JSON.stringify(res.response.data.detail));
                        if (res.response.status === 401 || res.response.status === 403){
                            // localStorage.clear();
                            cookie.remove("token", { path: '/' });
                            cookie.remove("email", { path: '/' });
                            window.location.assign('/');
                        }
                        this.setState({ data:{"Error":res.response.data.detail} });
                        //this.setState({ data:{"Error 403":"Forbidden"} });
                        this.setState({ loading: false });
                    });
            }
            else{
                axios.get(url)
                    .then(res =>{
                        const data = res.data;
                        this.setState({ data });
                        this.setState({ loading: false });
                });
            }
        }
    }
}
export default FetchData;
