import React from 'react';
import { Link } from 'react-router-dom';
import { LinkContainer, IndexLinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';
import FetchData from './FetchData';
import axios from 'axios';
import * as Settings from './Settings';
import cookie from 'react-cookies';
var loggedIn = require('./util.js').loggedIn

/*
data_fluid, full page or not
*/

class NavBar extends FetchData {
    handleLogOut (){
        var url = Settings.Host + "/api/accounts/logout/"
        axios.get(url,{
            headers: {
                "Authorization":"Token " + cookie.load('token',{ path: '/' })
            }
        })
            .then(res =>{
                console.log("logout!")
        })

        cookie.remove("token", { path: '/' });
        cookie.remove("email", { path: '/' });
        window.location.assign('/');
    }

    logedInMenu (){
        if (loggedIn()){
            return (
                <Nav pullRight>
                    <NavDropdown eventKey={3} title={cookie.load('email',{ path: '/' })} id={cookie.load('email',{ path: '/' })}>
                        <LinkContainer to='/changepassword/'>
                            <MenuItem>Change Password</MenuItem>
                        </LinkContainer>
                        <MenuItem onClick={this.handleLogOut}>Logout</MenuItem>
                    </NavDropdown>
                </Nav>
            );
        }
        else{
            return (
                <Nav pullRight>
                    <LinkContainer to='/login/'>
                        <NavItem>Login</NavItem>
                    </LinkContainer>
                </Nav>
            );
        }
    }

    redirect(url){
        window.open(url,'_blank');
    }

    render() {
        return (
            <Navbar inverse collapseOnSelect fluid={this.props.data_fluid}>
                <Navbar.Header>
                    <Navbar.Brand>
                        <Link to="/">Knowledgebase</Link>
                    </Navbar.Brand>
                    <Navbar.Toggle />
                </Navbar.Header>
                {window.location.href.indexOf("/login/") === -1 ?
                <Navbar.Collapse>
                    <Nav>
                        <IndexLinkContainer to="/home/">
                            <NavItem>Home</NavItem>
                        </IndexLinkContainer>
                        <NavDropdown eventKey={3} title="Dashboard" id="dashboard">
                            <LinkContainer to="/dashboard/visualization/">
                                <MenuItem>Visualization</MenuItem>
                            </LinkContainer>
                            <LinkContainer to="/dashboard/import/">
                                <MenuItem>Import Summary</MenuItem>
                            </LinkContainer>
                            <MenuItem divider />
                            <LinkContainer to="/dashboard/monsanto/">
                                <MenuItem>Monsanto Projects</MenuItem>
                            </LinkContainer>
                            <LinkContainer to="/dashboard/mad/">
                                <MenuItem>MAD Projects</MenuItem>
                            </LinkContainer>
                            <LinkContainer to="/dashboard/ad/">
                                <MenuItem>Atopic Dermatitis Projects</MenuItem>
                            </LinkContainer>
                            <LinkContainer to="/dashboard/cns/">
                                <MenuItem>Central Nervous System Projects</MenuItem>
                            </LinkContainer>
                            <LinkContainer to="/dashboard/bf/">
                                <MenuItem>IBD/Barrier Projects</MenuItem>
                            </LinkContainer>
                            <LinkContainer to="/dashboard/io/">
                                <MenuItem>Immuno-Oncology Projects</MenuItem>
                            </LinkContainer>
                            <LinkContainer to="/dashboard/md/">
                                <MenuItem>Metabolic Disease Projects</MenuItem>
                            </LinkContainer>
                        </NavDropdown>
                        <LinkContainer to='/query/'>
                            <NavItem>Tables</NavItem>
                        </LinkContainer>
                        <NavDropdown eventKey={3} title="New" id="new">
                            <LinkContainer to="/form/customers/organization/detail/0/">
                                <MenuItem>Organization</MenuItem>
                            </LinkContainer>
                            <LinkContainer to="/form/customers/investigator/detail/0/">
                                <MenuItem>PI or Investigator</MenuItem>
                            </LinkContainer>
                            <LinkContainer to="/form/projects/project/detail/0/">
                                <MenuItem>Project</MenuItem>
                            </LinkContainer>
                            <MenuItem divider />
                            <LinkContainer to="/form/protocols/extractionmethod/detail/0/">
                                <MenuItem>Extraction Method</MenuItem>
                            </LinkContainer>
                            <LinkContainer to="/form/protocols/pcrmethod/detail/0/">
                                <MenuItem>Amplicon</MenuItem>
                            </LinkContainer>
                            <LinkContainer to="/form/protocols/sequencingmethod/detail/0/">
                                <MenuItem>Sequencing Method</MenuItem>
                            </LinkContainer>
                            <LinkContainer to="/form/protocols/phylochipmethod/detail/0/">
                                <MenuItem>Phylochip Method</MenuItem>
                            </LinkContainer>
                            <LinkContainer to="/form/protocols/shotgunmethod/detail/0/">
                                <MenuItem>Shotgun Method</MenuItem>
                            </LinkContainer>
                            <LinkContainer to="/form/protocols/metabolomeseparationmethod/detail/0/">
                                <MenuItem>Metabolome Separation Method</MenuItem>
                            </LinkContainer>
                            <LinkContainer to="/form/protocols/metabolomedetectionmethod/detail/0/">
                                <MenuItem>Metabolome Detection Method</MenuItem>
                            </LinkContainer>
                            <LinkContainer to="/form/protocols/abundancesetgenerationmethod/detail/0/">
                                <MenuItem>AbundanceSet Generation Method</MenuItem>
                            </LinkContainer>
                        </NavDropdown>
                        <NavDropdown eventKey={3} title="Initiate Pipeline" id="Initiate Pipeline">
                            <LinkContainer to="/form/backends/labii2kb/detail/0/">
                                <MenuItem>Import Labii2KB<br /><span style={{fontSize:10, color: 'grey'}}>For Solutions</span></MenuItem>
                            </LinkContainer>
                            <LinkContainer to="/form/backends/kbetl/detail/0/">
                                <MenuItem>KBETL<br /><span style={{fontSize:10, color: 'grey'}}>Import projects and biospecimen</span></MenuItem>
                            </LinkContainer>
                            <MenuItem divider />
                            <LinkContainer to='/form/staging/'>
                                <MenuItem>Stage NCBI Project<br /><span style={{fontSize:10, color: 'grey'}}>Download fastq files</span></MenuItem>
                            </LinkContainer>
                            <LinkContainer to='/form/movetos3/'>
                                <MenuItem>Move to S3<br /><span style={{fontSize:10, color: 'grey'}}>Move and link project stored in unstructured or other non-standardized S3 locations</span></MenuItem>
                            </LinkContainer>
                            <MenuItem divider />
                            <LinkContainer to='/form/paw/'>
                                <MenuItem>Submit Lab Assays<br /><span style={{fontSize:10, color: 'grey'}}>For processing and submission to HeliosDB.</span></MenuItem>
                            </LinkContainer>
                        </NavDropdown>
                        <NavDropdown eventKey={3} title="Links" id="links">
                            <LinkContainer to='/query/api/projects/project/simple/id__isnull=false/'>
                                <MenuItem>Projects</MenuItem>
                            </LinkContainer>
                            <LinkContainer to='/query/api/lims/substance/simple/substance_type=Biospecimen/'>
                                <MenuItem>Biospecimen</MenuItem>
                            </LinkContainer>
                            <LinkContainer to='/query/api/lims/reportcollection/simple/id__isnull=false/'>
                                <MenuItem>Report Collection</MenuItem>
                            </LinkContainer>
                            <LinkContainer to='/query/api/customers/organization/simple/id__isnull=false/'>
                                <MenuItem>Organizations</MenuItem>
                            </LinkContainer>
                            <LinkContainer to='/query/api/protocols/protocol/simple/id__isnull=false/'>
                                <MenuItem>Protocols</MenuItem>
                            </LinkContainer>
                            <MenuItem divider />
                            <MenuItem onClick={() => {this.redirect('https://kbapi.dev.secondgenome.com')}}>Test API</MenuItem>
                            <MenuItem onClick={() => {this.redirect('https://kb.dev.secondgenome.com')}}>Test Knowledgebase</MenuItem>
                            <MenuItem onClick={() => {this.redirect('https://kbapi.secondgenome.com')}}>API</MenuItem>
                            <MenuItem onClick={() => {this.redirect('https://kb.secondgenome.com')}}>Knowledgebase</MenuItem>
                        </NavDropdown>
                    </Nav>
                    {this.logedInMenu()}
                </Navbar.Collapse>
                : null}
            </Navbar>
        );
    }
}
export default NavBar;
