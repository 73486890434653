import axios from 'axios';
import cookie from 'react-cookies';

export const post = (config) => {
    const {url, data, history, setDisabled, setResult} = config;
    return axios.post(url,
        JSON.stringify(data), {
            headers: {
                'Authorization': 'Token ' + cookie.load('token', {path: '/'}),
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            setResult(response.data);
        })
        .catch((error) => {
            setResult(error.response.data);
            if (error.response.status === 401 || error.response.status === 403) {
                cookie.remove('token', {path: '/'});
                cookie.remove('email', {path: '/'});
                history.push('/');
            }
        })
        .finally(() => setDisabled(false));
}
