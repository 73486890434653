import React, {Component} from 'react';
import {Grid, Row, Col, Panel, FormGroup, Button, FormControl, Table} from 'react-bootstrap';
import axios from 'axios';
import * as Settings from '../common/Settings';
import cookie from 'react-cookies';

class ChangePassword extends Component{
    constructor(props){
        super(props);
        this.state = {
            oldpassword: "",
            newpassword: "",
            button: "Submit",
            errors: [],
            message: "",
        };
    }

    updateErrors = (action, error)=>{
        if (action === "add"){
            if (this.state.errors.indexOf(error) === -1){
                var errors = this.state.errors;
                errors.push(error)
                this.setState({errors: errors})
            }
        }
        else{
            if (this.state.errors.indexOf(error) !== -1){
                var index = this.state.errors.indexOf(error);
                var errors = this.state.errors;
                errors.splice(index, 1);
                this.setState({errors: errors})
            }
        }
    }

    checkOldPassword = ()=>{
        var el = document.getElementsByName("oldpassword")[0];
        var error = "Please provide your old password!";
        if (el.value === ""){
            this.updateErrors("add", error);
        }
        else{
            this.updateErrors("remove", error);
            this.setState({oldpassword: el.value});
        }
    }

    checkNewPassword1 = ()=>{
        var el = document.getElementsByName("newpassword1")[0];
        var error = "Please provide a new password!";
        if (el.value === ""){
            this.updateErrors("add", error);
        }
        else{
            this.updateErrors("remove", error);
            this.setState({newpassword: el.value});
        }
    }

    checkNewPassword2 = ()=>{
        var el = document.getElementsByName("newpassword2")[0];
        var error = "Please retype the new password!";
        if (el.value === ""){
            this.updateErrors("add", error);
        }
        else{
            this.updateErrors("remove", error);
            error = "The two password does not match!";
            if (el.value !== this.state.newpassword){
                this.updateErrors("add", error);
            }
            else{
                this.updateErrors("remove", error);
            }
        }
    }

    handleChange = (event) => {
        this.checkOldPassword();
        this.checkNewPassword1();
        this.checkNewPassword2();
    }

    handleSubmit = (event) => {
        this.setState({button: "Submitting..."});
        axios.post(`${Settings.Host}/api/accounts/user/password/ `,
            JSON.stringify({
                old_password: this.state.oldpassword,
                password: this.state.newpassword
            }),{
            headers: {
                "Authorization":"Token " + cookie.load('token',{ path: '/' }),
                "Content-Type": "application/json"
            }
        })
        .then((response) =>{
            this.setState({message: "Your password has be updated successfully!"});
        })
        .catch((error) =>{
            console.log(JSON.stringify(error));
            this.setState({message: error.response.data.detail});
        });
    }

    render (){
        return (
            <Grid>
                <Row>
                    <Col xs={0} md={4}></Col>
                    <Col xs={12} md={4}>
                        <Panel header="Change Password">
                            <form>
                                <FormGroup controlId="formBasicPassword">
                                    <FormControl
                                    type="password"
                                    placeholder="Old Password"
                                    name="oldpassword"
                                    onChange={this.handleChange}
                                    />
                                </FormGroup>
                                <FormGroup controlId="formBasicPassword">
                                    <FormControl
                                    type="password"
                                    placeholder="New Password"
                                    name="newpassword1"
                                    onChange={this.handleChange}
                                    />
                                </FormGroup>
                                <FormGroup controlId="formBasicPassword">
                                    <FormControl
                                    type="password"
                                    placeholder="Retye New Password"
                                    name="newpassword2"
                                    onChange={this.handleChange}
                                    />
                                </FormGroup>
                                {this.state.errors.length === 0 ? <Button type="button" onClick={this.handleSubmit}>{this.state.button}</Button> : null}
                                {this.state.errors.length > 0 ?
                                    <div style={{color: "red"}}>
                                        {this.state.errors.map((error, i)=>{
                                            return <p key={i}>Error: {error}</p>
                                        })}
                                    </div>
                                : null}
                                {this.state.message !== "" ? <div><br />{this.state.message}</div> : null}
                            </form>
                        </Panel>
                    </Col>
                    <Col xs={0} md={4}></Col>
                </Row>
            </Grid>
        );
    }
}
export default ChangePassword;
