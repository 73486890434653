import React, { Component } from 'react';
import {Grid, Row, Col, Panel, Table } from 'react-bootstrap';
import ObjectCount from './ObjectCount';
import ProjectName from './ProjectName';
import WeeklySummary from './WeeklySummary';

class Import extends Component{
    constructor(props){
        super(props);
        this.state = {
            status: [
                "Empty project",
                "Biospecimens accessioned",
                "Metadata curated",
                "Files linked",
                "Abundances loaded",
            ]
        }
    }


    render() {
        function getMondays() {
            var d = new Date(),
                mondays = [];

            // Get the first Monday in the month
            while (d.getDay() !== 1) {
                d.setDate(d.getDate() + 1);
            }
            // Get all the other Mondays in the month
            while (mondays.length <= 4) {
                mondays.push(new Date(d.getTime()));
                d.setDate(d.getDate() - 7);
            }
            return mondays;
        }

        function Date2String(d){
            return d.getFullYear() + "-" + (d.getMonth()+1) + "-" + d.getDate();
        }

        function ImportSummaryWeeks(){
            var mondays = getMondays();
            return mondays.map(function(monday,i){
                var date_start = Date2String(mondays[i])
                if (i === 0){
                    var today = new Date();
                    var date_end = Date2String(today);
                }
                else{
                    date_end = Date2String(mondays[i-1]);
                }
                return <WeeklySummary date_start={date_start} date_end={date_end} key={i}></WeeklySummary>
            });
        }

        return (
            <Grid>
                <Row>
                    <Col xs={12} md={8}>
                        <Panel header="Data Import Summary">
                            <Table hover striped>
                                <thead>
                                    <tr>
                                        <th>Week</th>
                                        <th>Projects</th>
                                        <th>Biospecimen</th>
                                        <th>Extract</th>
                                        <th>Library</th>
                                        <th>OmicsFileSet</th>
                                        <th>AbundanceSet</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ImportSummaryWeeks()}
                                </tbody>
                            </Table>
                        </Panel>
                    </Col>
                    <Col xs={12} md={4}>
                        <Panel header="Overall Summary">
                            <Table hover striped>
                                <tbody>
                                    <tr>
                                        <td>Projects</td>
                                        <td><ObjectCount data_url="/api/projects/project/key/?limit=1" /></td>
                                    </tr>
                                    <tr>
                                        <td>Biospecimen</td>
                                        <td><ObjectCount data_url="/api/lims/substance/key/?substance_type=Biospecimen&limit=1" /></td>
                                    </tr>
                                    <tr>
                                        <td>Extract</td>
                                        <td><ObjectCount data_url="/api/lims/substance/key/?substance_type=Extract&limit=1" /></td>
                                    </tr>
                                    <tr>
                                        <td>Library</td>
                                        <td><ObjectCount data_url="/api/lims/substance/key/?substance_type=Library&limit=1" /></td>
                                    </tr>
                                    <tr>
                                        <td>OmicsFileSet</td>
                                        <td><ObjectCount data_url="/api/lims/substance/key/?substance_type=OmicsFileSet&limit=1" /></td>
                                    </tr>
                                    <tr>
                                        <td>AbundanceSet</td>
                                        <td><ObjectCount data_url="/api/lims/substance/key/?substance_type=AbundanceSet&limit=1" /></td>
                                    </tr>
                                    <tr>
                                        <td>Metadata</td>
                                        <td><ObjectCount data_url="/api/lims/metadata/key/?limit=1" /></td>
                                    </tr>
                                    <tr>
                                        <td>Ontology</td>
                                        <td><ObjectCount data_url="/api/ontology/ontology/key/?limit=1" /></td>
                                    </tr>
                                    <tr>
                                        <td>Protocols</td>
                                        <td><ObjectCount data_url="/api/protocols/protocol/key/?limit=1" /></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Panel>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Panel header="Project Status">
                            <Table hover striped>
                                <thead>
                                    <tr>
                                        <th>All</th>
                                        {this.state.status.map((s, i)=>{
                                            return <th key={i}>{s}</th>
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><ObjectCount data_url="/api/projects/project/key/?limit=1" /></td>
                                        {this.state.status.map((s, i)=>{
                                            return <td key={i}><ObjectCount data_url={`/api/projects/project/key/?limit=1&status=${s}`} /></td>
                                        })}
                                    </tr>
                                    <tr>
                                        <td></td>
                                        {this.state.status.map((s, i)=>{
                                            return <td key={i}><ProjectName data_url={`/api/projects/project/key/?limit=5000&status=${s}`} /></td>
                                        })}
                                    </tr>
                                </tbody>
                            </Table>
                        </Panel>
                    </Col>
                </Row>
            </Grid>
        );
    }
}
export default Import;
