import React from 'react';
import {Panel, Label} from 'react-bootstrap';
import * as Settings from '../common/Settings';
import axios from 'axios';
import cookie from 'react-cookies';

class HomeStats extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            stats: {},
        }
    }

    componentDidMount(){
        axios.get(Settings.Host + "/backends/stats/",{
            headers: {
                "Authorization":"Token " + cookie.load('token',{ path: '/' })
            }
        })
            .then(res =>{
                this.setState({ stats: res.data });
        })
    }

    render = () => (
        <Panel header="Stats">
            {Object.keys(this.state.stats).includes("branch") ?
                <div>
                    <b>Branch: </b><br />
                        <ul>
                            <li><b>Name: </b>{this.state.stats.branch}</li>
                            <li><b>SHA: </b>{this.state.stats.branch_sha}</li>
                            <li><b>Updated On: </b>{this.state.stats.last_pull_timestamp}</li>
                            <li><b>Tag: </b>{this.state.stats.tag}</li>
                        </ul>
                    <b>OWL Import:</b><br />
                        <ul>
                            <li><b>Version: </b>{this.state.stats.owl_import.file_name}</li>
                            <li><b>Imported On: </b>{this.state.stats.owl_import.timestamp}</li>
                        </ul>
                    <b>Backend:</b><br />
                        <ul>
                            <li><b>Django version: </b>{this.state.stats.django_version}</li>
                        </ul>
                    <b>Logs: </b><br />
                    <ul>
                        {this.state.stats.logs.map((l)=>{
                            return <li>{l}</li>
                        })}
                    </ul>
                </div>
            : "Loading..."}

        </Panel>
    )
}

export default HomeStats;
