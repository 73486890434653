import React, { Component } from 'react';
import { Grid, Row, Col, Panel } from 'react-bootstrap';
import HomePermission from './HomePermission';
import HomeStats from './HomeStats';

class Home extends Component{
    render() {
        return (
            <Grid>
                <Row>
                    <Col xs={12} md={8}>
                        <img src='/data_organization.png' style={{width:'100%'}}></img>
                        <br /><br />
                        <Panel header="Readme">
                            <h4><a href="https://docs.google.com/document/d/1eb4hNcesafMEEy02wGJfJZJgPI_VNXBiBtT_BT8UfWI/edit#heading=h.5vo9t43rq9wv" target="_blank" rel="noopener noreferrer">Knowledgebase Readme</a></h4>
                            <ul>
                            <li><b>Projects: </b>A collection of biological specimen (content of a tube) and everything that is derived from them in the lab (tubes) or on a
                  computer (data files, counts); unique project ID in the KB is the sg_project_id, e.g. ABBV_BHarvey_EPAN17_0382</li>
                                <li><b>Substances</b>
                                    <ul>
                                        <li><b>Biospecimen:</b> Content of a tube. A preserved substance that was once living. E.g. cell, tissue, soil, water.</li>
                                        <li><b>Extract:</b> Content of a tube. Result of an extraction experiment, typically DNA or RNA.</li>
                                        <li><b>Library:</b> Content of a tube. Result of a library prep experiment, typically PCR. A library contains a whole bunch
                                        of molecules.</li>
                                        <li><b>OmicsFileSet (OFS):</b> Set of data files. All files that result from a particular sequencing/hybridization experiment performed on a particular library.</li>
                                        <li><b>AbundanceSet:</b> The set of feature (OTU, ASV, gene) abundances observed in a particular OmicsFileSet.</li>
                                    </ul>
                                </li>
                                <li><b>Report Collection:</b> A set of Substances that are used for a particular analysis; Substances can come from one or multiple projects.</li>
                                <li><b>Metadata:</b> Some attribute of a project or substance; stored as key - value pair; controlled vocabulary; typically organized in an ontology</li>
                                <li><b>Tag:</b> Some attribute of a project or substance; stored as key - value pair; not organized in an ontology</li>
                                <li><b>Barcode:</b> A unique identifier of a substance, e.g. B170029, Z00462, L1700029, L1700029_M02273_8_000000000-AYDH5</li>
                            </ul>
                        </Panel>
                    </Col>
                    <Col xs={12} md={4}>
                        <HomePermission />
                        <HomeStats />
                    </Col>
                </Row>
            </Grid>
        );
    }
}
export default Home;
