import React from 'react';
//import PropType from 'props-type';
import {Button, Grid, Row, Col} from 'react-bootstrap';
import { renderToString } from 'react-dom/server';
//import Dymo from 'dymojs';
//import 'http://labelwriter.com/software/dls/sdk/js/DYMO.Label.Framework.latest.js';

// npm install dymojs --save

// drag files into two different targets

class Test extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            schema: {},
            uischema: {},
            formData: {},
            height: 31.75,
            width: 57.15
        }
    }

    doPrint = ()=>{
        var myWindow = window.open("", "", "width=200,height=100");
        myWindow.document.write("<p>This is 'MsgWindow'. I am 200px wide and 100px tall!</p>");
        myWindow.document.write("<p>dd!</p>");
        //window.print();
        myWindow.print();
    }

    printContent = (ob)=>{
        // var x2 = 3.7795275590551*this.state.height;
        // var y2 = 3.7795275590551*this.state.width + 16;
        var x2 = 116;
        var y2 = 216;
        console.log(x2);
        console.log(y2);
        var myWindow = window.open("", "", "width="+y2+",height="+x2);
        //var code = renderToString(<QRCode value={this.state.form.formData['uid']} style={{ width: this.state.height*3.7795275590551,paddingRight:10 }} />);
        //var code = renderToString(<QRCode value={this.state.form.formData['uid']} style={{ width: 64,paddingRight:10 }} />);
        //var text = this.state.form.formData['uid'] + ": " + this.state.form.formData['name'];

        var code = "<img src='data:image/png;name=qr-code-for-osxdaily.png;base64,iVBORw0KGgoAAAANSUhEUgAAANwAAADcAQAAAAAzIfIsAAABFklEQVR4AeWYsa3DMBBDGfzCpUfIKB7tezSPkhFUpgj8YkIUAngEHRsd9DqBR52kaAHEridt+SiaCgJ454HgkOTSelWCu7S2BWvfXitcUNJWEH5Sai0MOQUc7pWKML0i4Z1+QtZcMOqZ4Hb4BWMdyNAp6R9DolYGylq9igsC77/T5fGsA71c9K1H5oQOoRRk325zQneMy4mgDJsPAXwI7ReMheAIglMJRil3YyEIbof0AHYMIxMqwbEjqTtG6ZU6MOMwIDvGI0PMMxkE90C/+jIAJCnKQKLxDMoHgVQJRvYGcGjNMygqAG+ZkP8hQAnGMjC/AnkjacjlnDAac7AKQilBYFOQwyoDLUNxuyLrwCg73SZRGfgF9SY9Di1nugUAAAAASUVORK5CYII=' height=64 width=64></img>";
        var text = "EP1: some experiment, and what if some more text is also added to the label";

        var label = renderToString(<span>{text}</span>);
        var html = "";
        var x1 = 64;
        var y1 = 64;
        var len_text = text.length;
        var lines = len_text;
        var padding = 8;
        if (ob.target.name === "code"){
            html = code;
        }
        else if (ob.target.name === "label"){
            html = "<span style='font-size: 20vw;'>" + label + "</span>";
            x1 = x2 - padding*2;
            y1 = y2 - padding*2;
        }
        else if (ob.target.name === "code_label"){
            html = code + "<br />" + label;
        }
        else if (ob.target.name === "code-label"){
            html = "<table><tr><td>" + code + "</td><td>" + label + "</td></tr></table>";
        }

        var r = Math.min(x2/(x1+padding*2),y2/(y1+padding*2));

        myWindow.document.write("<div style='zoom: "+r*100+"%;'>"+html+"</div>");
        myWindow.print();
    }

    render(){
        console.log(renderToString(<Button>dd</Button>))
        return (
            <Grid>
                <Row>
                    <Col xs={12}>
                        <button onClick={this.printContent} name="label">print</button>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

// Test.propsType = {
//
// }

export default Test;
