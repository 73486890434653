import React from 'react';
import PropTypes from 'prop-types';
import {Label, Table} from 'react-bootstrap';

class FormMatch extends React.Component{
    render(){
        var table_name = this.props.tableName
        if (table_name === 'pcrmethod') {
            table_name = 'amplicon method';
        }
        return (
            <div>
                <h4>There are {this.props.matchResponse.count} {table_name}s matching your input value.</h4>
                <h4>
                    {this.props.matchResponse.count === 0 ?
                        <Label bsStyle="success">Safe to Submit</Label>
                    :
                        <Label bsStyle="danger">Duplicate records found</Label>
                    }

                </h4>
                <p>This funtion automatically checks whether the data you input matches any existing {table_name} in database. Use this function to 1) <b>Avoid creating duplicate {table_name}s</b>, if your {table_name} is in the list, SKIP submitting this form; 2) <b>Save time in creating a similar {table_name}</b>, click a matched {table_name} to auto fill the rest of fields and then only change the fields that are different.</p>
                <b>Instructions:</b>
                <ul>
                    <li>Type values in the form, and matches will be updated. The function return results which match all fields, regardless of case. <b>Tip:</b> type in part of value returns more matches.</li>
                    <li>If the number of matches is more than 0, your {table_name} already exists in the database. </li>
                    <li>If you believe the {table_name} you are going to submit is similar to one in the match list, click that {table_name} to auto fill with the existing data. Then change those fields that are different.</li>
                    <li>If you have put in all data and the number of matches is 0, you are safe to submit.</li>
                </ul>
                {this.props.matchResponse.count > 0 ? <div><hr />Top 10 matches:</div> : null}
                {this.props.matchResponse.count > 0 ?
                    <Table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                {this.props.tableName.indexOf("method") !== -1 ? <th>Protocol ID</th> : null}
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.matchResponse.results.map((row,i)=>{
                                return(
                                <tr key={i}>
                                    <td>{row.id}</td>
                                    <td><a onClick={this.props.handleAutoFill ? this.props.handleAutoFill : null} name={row.id}>{row.name ? row.name : row.email}</a></td>
                                    {this.props.tableName.indexOf("method") !== -1 ? <td>{row.protocol_id}</td> : null}
                                </tr>);
                            })}
                        </tbody>
                    </Table>
                : null}
            </div>
        );
    }
}

FormMatch.propTypes = {
    matchResponse: PropTypes.object.isRequired, // the final return results
    handleCheck: PropTypes.func, // function to check
    tableApp: PropTypes.string, // the table app
    tableName: PropTypes.string, // the table name
    handleAutoFill: PropTypes.func, // function to auto fill the data
}

export default FormMatch;

// <Button bsStyle="primary" onClick={this.props.handleCheck}>Check similar {this.props.tableName}s</Button>
// <hr />
