import React from 'react';
import {Grid, Row, Col} from 'react-bootstrap';
import axios from 'axios';
import cookie from 'react-cookies';
import * as Settings from '../common/Settings';
import {ComposedChart, Area, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';


class Substance extends React.PureComponent{
    constructor(props){
        super(props);
        this.state = {
            mondays: [], // the date of mondays
            data: [], // all the data
        }
    }

    date2String = (d)=>{
        return d.getFullYear() + "-" + (d.getMonth()+1) + "-" + d.getDate();
        //return (d.getMonth()+1) + "-" + d.getDate();
    }

    getEmptyData = ()=>{
        // need mondays
        var data = [];
        this.state.mondays.reverse().map((m)=>{
            var d = {};
            d["name"] = m;
            d["project"] = 0;
            d["biospecimen"] = 0;
            d["extract"] = 0;
            d["library"] = 0;
            d["omicsfileset"] = 0;
            data.push(d);
        });
        this.setState({data},()=>{
            this.collectCount();
        });
    }

    getMondays =() =>{
        var d = new Date()
        var mondays = [];

        // Get the first Monday in the month
        while (d.getDay() !== 1) {
            d.setDate(d.getDate() + 1);
        }
        // Get all the other Mondays in the month
        while (mondays.length <= 12) {
            mondays.push(this.date2String(new Date(d.getTime())));
            d.setDate(d.getDate() - 7);
        }
        this.setState({mondays}, ()=>{
            this.getEmptyData();
        });
    }

    getCount = (start, type)=>{
        // start as start date, YYYY-MM-DD
        // type as project, biospecimen, et.al
        var url = "/api/lims/substance/key/?substance_type="+type.charAt(0).toUpperCase() + type.slice(1)+"&dtstamp__lte="+start+"T23:59:59&limit=1";
        if (type === "project"){
            url = "/api/projects/project/key/?dtstamp__lte="+start+"T23:59:59&limit=1";
        }
        console.log(Settings.Host + url);
        axios.get(Settings.Host + url,{
            headers: {
                "Authorization":"Token " + cookie.load('token',{ path: '/' })
            }
        })
            .then(res =>{
                const data = res.data;
                var d = JSON.parse(JSON.stringify(this.state.data));
                d[this.state.mondays.indexOf(start)][type] = data.count;
                console.log(data.count);
                this.setState({data: d});
        })
    }

    collectCount = ()=>{
        this.state.mondays.map((monday)=>{
            this.getCount(monday, "biospecimen");
            this.getCount(monday, "extract");
            this.getCount(monday, "library");
            this.getCount(monday, "omicsfileset");
            this.getCount(monday, "project");
        });
    }

    componentWillMount(){
        this.getMondays();
    }

    componentDidMount(){

    }

    render() {
        //console.log(this.state.data);
        return (
            <Grid fluid>
                <Row>
                    <Col xs={12} md={12}>
                        <ComposedChart width={800} height={400} data={this.state.data}
                        margin={{top: 20, right: 20, bottom: 20, left: 20}}>
                            <CartesianGrid stroke='#f5f5f5'/>
                            <XAxis dataKey="name"/>
                            <YAxis yAxisId="left" orientation="left" type="number" />
                            <YAxis yAxisId="right" orientation="right" stroke="#413ea0" domain={[0, 400]} />

                            <Tooltip />
                            <Legend />
                            <Area yAxisId="left" type='monotone' dataKey='biospecimen' fill='#e0e0e0' stroke='#424242'/>
                            <Area yAxisId="left" type='monotone' dataKey='library' fill='#b3e5fc' stroke='#01579b'/>
                            <Area yAxisId="left" type='monotone' dataKey='extract' fill='#c8e6c9' stroke='#2e7d32'/>
                            <Area yAxisId="left" type='monotone' dataKey='omicsfileset' fill='#ffccbc' stroke='#ff5722'/>
                            <Bar yAxisId="right" dataKey='project' barSize={20} fill='#413ea0' label={{ position: 'top' }} />
                        </ComposedChart>
                    </Col>
                </Row>
            </Grid>
        );
    }
}
export default Substance;

// <Bar dataKey='pv' barSize={20} fill='#413ea0' />
// <Line type='monotone' dataKey='uv' stroke='#ff7300' />
