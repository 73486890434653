import React from 'react';
import DetailViewData from '../detail/DetailViewData';

//host, the host ip address and port
//viewMethod
//tableApp
//tableName
//tableID

const DetailView = ({match}) => (
    <DetailViewData match={match} data_url={`/api/${match.params.tableApp}/${match.params.tableName}/${match.params.viewMethod}/${match.params.tableID}/`} />
);
export default DetailView;
