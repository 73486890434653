import React, { Component } from 'react';
import axios from 'axios';
import cookie from 'react-cookies';
import * as Settings from '../common/Settings';
import DashboardGrid from './DashboardGrid';

class Other extends Component{
    constructor(props){
        super(props);
        this.state = {
            dashboard_data: {
                'substance_counts_by_substance_type': [],
                'biospecimen_counts_by_period_of_life': [],
                'biospecimen_counts_by_host_disease_status': [],
                'biospecimen_counts_by_histological_status': [],
                'biospecimen_counts_by_organism': [],
                'biospecimen_counts_by_type': [],
                'extract_counts_by_type': [],
                'library_counts_by_region': [],
            },
        }
    }

    componentWillMount(){
        axios.get(Settings.Host + "/api/backends/dashboard_data/?project_type=CNS",{
            headers: {
                "Authorization":"Token " + cookie.load('token',{ path: '/' })
            }
        })
            .then(res =>{
                this.setState({ dashboard_data: res.data });
        });
    }

    render() {
        return (<DashboardGrid dashboard_data={this.state.dashboard_data} />);
    }
}
export default Other;