import React, {Component} from 'react';
import {} from 'react-bootstrap';
import ObjectCount from './ObjectCount';

/*
Return a row of basic summary items
need date
*/

class WeeklySummary extends Component{
    render (){
        return (
            <tr>
                <td>{this.props.date_start}</td>
                <td><ObjectCount data_url={`/api/projects/project/key/?dtstamp__gte=${this.props.date_start}T00:00:00&dtstamp__lte=${this.props.date_end}T23:59:59&limit=1`} /></td>
                <td><ObjectCount data_url={`/api/lims/substance/key/?substance_type=Biospecimen&dtstamp__gte=${this.props.date_start}T00:00:00&dtstamp__lte=${this.props.date_end}T23:59:59&limit=1`} /></td>
                <td><ObjectCount data_url={`/api/lims/substance/key/?substance_type=Extract&dtstamp__gte=${this.props.date_start}T00:00:00&dtstamp__lte=${this.props.date_end}T23:59:59&limit=1`} /></td>
                <td><ObjectCount data_url={`/api/lims/substance/key/?substance_type=Library&dtstamp__gte=${this.props.date_start}T00:00:00&dtstamp__lte=${this.props.date_end}T23:59:59&limit=1`} /></td>
                <td><ObjectCount data_url={`/api/lims/substance/key/?substance_type=OmicsFileSet&dtstamp__gte=${this.props.date_start}T00:00:00&dtstamp__lte=${this.props.date_end}T23:59:59&limit=1`} /></td>
                <td><ObjectCount data_url={`/api/lims/substance/key/?substance_type=AbundanceSet&dtstamp__gte=${this.props.date_start}T00:00:00&dtstamp__lte=${this.props.date_end}T23:59:59&limit=1`} /></td>
            </tr>
        );
    }
}
export default WeeklySummary;
