import React from 'react';
import {Button, Col, Grid, Row} from 'react-bootstrap';
import Form from "@rjsf/core";
import JSONPretty from 'react-json-pretty';
import KBDescriptionField from '../common/KBDescriptionField';

const fields = {
    DescriptionField: KBDescriptionField,
};

const ResultsForm = ({form, disabled, result, handleSubmit}) => {

    return (
        <Grid fluid>
            <Row>
                <Col xs={12}>
                    {Object.keys(form).length > 0 ?
                        <Form schema={form.schema}
                              formData={form.formData}
                              uiSchema={form.uiSchema}
                              onSubmit={handleSubmit}
                              {...{fields, disabled}}
                        >
                            <Button {...{disabled, type: 'submit', bsStyle: 'info'}}>Submit</Button>
                        </Form>
                        : 'Loading...'}
                </Col>
                {Object.keys(result).length > 0 ?
                    <Col xs={12}>
                        <hr/>
                        <h3>Results:</h3>
                        <JSONPretty id="json-pretty" data={result}/>
                    </Col>
                    : null}
            </Row>
        </Grid>
    );
};

export default ResultsForm;
