import React from 'react';
import * as Settings from '../common/Settings';
import axios from 'axios';
import cookie from 'react-cookies';

/*
Return a row of count
need data_url and title
*/

class ProjectName extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            data: {results:[]},
        }
    }

    componentDidMount(){
        //alert(Settings.Host + this.props.DataUrl + "&format=json");
        var Host = Settings.Host;
        // if (this.props.data_host){
        //     Host = this.props.data_host;
        // }
        if (this.props.data_url){
            if (this.props.data_url.indexOf("?") !== -1){
                var url = Host + this.props.data_url + "&format=json"
            }
            else{
                url = Host + this.props.data_url + "?format=json"
            }
            axios.get(url,{
                headers: {
                    "Authorization":"Token " + cookie.load('token',{ path: '/' })
                }
            })
                .then(res =>{
                    const data = res.data;
                    console.log(data);
                    this.setState({ data });
                    this.setState({ loading: false });
            })
                .catch(res =>{
                //alert("Error: "+ res.response.data.detail);
                if (res.response.status === 401){
                    // localStorage.clear();
                    cookie.remove("token", { path: '/' });
                    cookie.remove("email", { path: '/' });
                    window.location.assign('/');
                }
            });
        }
    }

    render (){
        if (this.state.loading) {
            return (<span>{Settings.Loading}</span>);
        }
        else{
            return (
                <div>
                    {this.state.data.results.map((r, i)=>{
                        console.log(r)
                        return <div key={i}><a href={`/detail/api/projects/project/detail/${r.value}/`} target="_blank" rel="noopener noreferrer">{r.label}</a></div>
                    })}
                </div>
            );
        }
    }
}

export default ProjectName;
