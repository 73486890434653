import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import FetchData from '../common/FetchData';
import * as Settings from '../common/Settings'
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Grid, Row, Col, Table, Button, ButtonToolbar } from 'react-bootstrap';
import cookie from 'react-cookies';
var APIUrl2ReactLink = require('../common/util.js').APIUrl2ReactLink

class DetailViewData extends FetchData{
    handleDelete(url){
        axios.delete(Settings.Host + url,{
            headers: {
                "Authorization":"Token " + cookie.load('token',{ path: '/' })
            }
        })
        .then(res =>{
            window.location.assign("/query/api/" + this.props.data_url.split('/').slice(2,4).join('/') + "/simple/id__isnull=false/");
        });
    }

    updateSubstance = ()=>{// update substance list for the reportcollection
        axios.get(Settings.Host + "/api/" + this.props.match.params.tableApp +"/"+ this.props.match.params.tableName + "/update/" + this.props.match.params.tableID + "/",{
            headers: {
                "Authorization":"Token " + cookie.load('token',{ path: '/' })
            }
        })
        .then(res =>{
            window.location.reload();
        })
    }

    getEditButton(state){
        return (
            <ButtonToolbar className="pull-right">
                {state.data.change_project ?
                    <LinkContainer to={`/form/${this.props.data_url.split('/').slice(2,).join('/')}`}>
                        <Button bsStyle="info">Edit {this.props.data_url.split('/')[3]}</Button>
                    </LinkContainer>
                : null}
                {state.data.delete_project ?
                    <Button bsStyle="danger" onClick={() => this.handleDelete(this.props.data_url)}>Delete {this.props.data_url.split('/')[3]}</Button>
                : null}
                {state.data.change_project && this.props.data_url.indexOf("substance") !== -1 && this.props.data_url.indexOf("substancefile") === -1 ?
                    <LinkContainer to={`/form/lims/metadata/detail/0/?substance=/api/lims/substance/simple/___id---${window.location.href.split("/")[8]}`}>
                        <Button bsStyle="default">Add MetaData</Button>
                    </LinkContainer>
                : null}
                {state.data.change_project && (
                    this.props.data_url.indexOf("substance") !== -1 ||
                    this.props.data_url.indexOf("project") !== -1 ||
                    this.props.data_url.indexOf("file") !== -1 ||
                    this.props.data_url.indexOf("experiment") !== -1
                ) ?
                    <LinkContainer to={`/form/lims/tag/detail/0/?${window.location.href.split("/")[6]}_id=${window.location.href.split("/")[8]}`}>
                        <Button bsStyle="default">Add Tag</Button>
                    </LinkContainer>
                : null}
                {state.data.change_project && this.props.data_url.indexOf("reportcollection") !== -1 ?
                    state.data.is_locked ? <Button bsStyle="default" disabled>Update Substances (Locked)</Button> : <Button bsStyle="default" onClick={this.updateSubstance}>Update Substances</Button>
                : null}
            </ButtonToolbar>
        );
    }

    render (){
        function ArrayItem(value,key){
            return value.map(function(v,i){
                if (v && (v.toString().indexOf(Settings.Domain) !== -1)){
                    var link = APIUrl2ReactLink(v);
                    v = <Link to={link}>{v}</Link>;
                }
                else if (typeof v === "object"){
                    v = JSON.stringify(v);
                }
                return <div key={`${key}.${i}`}>{v}<br /></div>
            });
        }

        function DetailRows(state){
            if (state.data.count === 0){
                return <tr><td>No data</td></tr>
            }
            else{
                return Object.keys(state.data || {}).map(function(key,i){
                    if (key !== "id"){
                        if (Array.isArray(state.data[key])){
                            var value = ArrayItem(state.data[key], i);
                        }
                        else{
                            if (state.data[key] && (state.data[key].toString().indexOf(Settings.Domain) !== -1)){
                                var link = APIUrl2ReactLink(state.data[key]);
                                value = <Link to={link}>{state.data[key]}</Link>;
                            }
                            else{
                                if (state.data[key] !== undefined && state.data[key] !== null){
                                    value = String(state.data[key]);
                                }
                                else{
                                    value = "";
                                }
                            }
                        }
                        return <tr key={i}>
                            <th>{key}</th>
                            <td>{value}</td>
                        </tr>;
                    }
                });
            }
        }

        if (this.state.loading) {
            return <div>{Settings.Loading}</div>
        }
        else{
            return (
                <Grid fluid>
                    <Row>
                        <Col xs={12}>
                            {this.getEditButton(this.state)}
                            <br /> <br />
                            <Table hover striped>
                                <tbody>
                                    {DetailRows(this.state)}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Grid>
            );
        }
    }
}

DetailViewData.propTypes = {
    data_url: PropTypes.string,
}

export default DetailViewData;
