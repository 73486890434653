import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import NavBar from './components/common/NavBar';
import Login from './components/auth/Login';
import ChangePassword from './components/auth/ChangePassword';
import Home from './components/home/Home';
import Import from './components/dashboard/Import';
import Monsanto from './components/dashboard/Monsanto';
import MAD from './components/dashboard/MAD';
import AD from './components/dashboard/AD';
import BF from './components/dashboard/BF';
import CNS from './components/dashboard/CNS';
import IO from './components/dashboard/IO';
import MD from './components/dashboard/MD';
import Visualization from './components/dashboard/Visualization';
import Substance from './components/visualization/Substance';
import QueryHome from './components/query/QueryHome';
import QueryView from './components/query/QueryView';
import DetailView from './components/detail/DetailView';
import FormView from './components/form/FormView';
import Staging from './components/form/Staging';
import MoveToS3 from './components/form/MoveToS3';
import PAW from './components/form/PAW';
import Test from './components/test/Test';
var loggedIn = require('./components/common/util.js').loggedIn

class App extends Component {
    constructor(props){
        super(props);
        this.state = {
            isAuthenticated: loggedIn()
        }
    }

    render() {
        const PrivateRoute = ({ component: Component, ...rest }) => (
            <Route {...rest} render={props => (
                this.state.isAuthenticated ? (
                    <Component {...props}/>
                ) : (
                    <Redirect to={{
                        pathname: '/login/',
                        state: { from: props.location }
                    }}/>
                )
            )}/>
        );

        return (
            <div>
                {window.location.href.indexOf("/visualization/") === -1 || window.location.href.indexOf("/dashboard/") !== -1 ? <NavBar /> : null}
                <Route exact path="/login/" component={Login} />
                <Route exact path="/visualization/substance/" component={Substance} />
                <PrivateRoute exact path="/changepassword/" component={ChangePassword} />
                <PrivateRoute exact path="/" component={Home}/>
                <PrivateRoute exact path="/home/" component={Home}/>
                <PrivateRoute exact path="/dashboard/import/" component={Import}/>
                <PrivateRoute exact path="/dashboard/monsanto/" component={Monsanto}/>
                <PrivateRoute exact path="/dashboard/mad/" component={MAD}/>
                <PrivateRoute exact path="/dashboard/ad/" component={AD}/>
                <PrivateRoute exact path="/dashboard/bf/" component={BF}/>
                <PrivateRoute exact path="/dashboard/cns/" component={CNS}/>
                <PrivateRoute exact path="/dashboard/io/" component={IO}/>
                <PrivateRoute exact path="/dashboard/md/" component={MD}/>
                <PrivateRoute exact path="/dashboard/visualization/" component={Visualization}/>
                <PrivateRoute exact path="/query/" component={QueryHome} />
                <PrivateRoute path="/query/:App/:tableApp/:tableName/:viewMethod/:query/" component={QueryView} />
                <PrivateRoute path="/detail/api/:tableApp/:tableName/:viewMethod/:tableID/" component={DetailView} />
                <PrivateRoute exact path="/form/:tableApp/:tableName/:viewMethod/:tableID/" component={FormView} />
                <PrivateRoute exact path="/form/staging/" component={Staging}/>
                <PrivateRoute exact path="/form/movetos3/" component={MoveToS3}/>
                <PrivateRoute exact path="/form/paw/" component={PAW}/>
                <Route exact path="/test/" component={Test} />
            </div>
        );
    }
}
export default App;


//{this.props.location.pathname.indexOf("/visualization/") === -1 ? null : <NavBar />}
