import React, { Component } from 'react';
import { Grid, Row, Col, Table, Panel } from 'react-bootstrap';
import QueryForm from './QueryForm';
import TableList from './TableList';
import { Link } from 'react-router-dom';


class QueryHome extends Component{
    render(){
        return (
            <Grid fluid>
                <QueryForm />
                <Row>
                    <Col xs={12} md={5}>
                        <Panel header="Tables">
                            <TableList data_url="/api/tables/?limit=30" />
                        </Panel>
                    </Col>
                    <Col xs={12} md={7}>
                        <Panel header="Example queries">
                            <Table hover>
                                <tbody>
                                    <tr>
                                        <td>
                                            Give me all biospecimen that have metadata "Dietary Regime" associated with them<br />
                                            <Link to="/query/api/lims/substance/detail/substance_type=Biospecimen&metadata__label__icontains=Dietary%20Regime/">https://kbapi.secondgenome.com/api/lims/substance/detail/ ?substance_type=Biospecimen &metadata__label__icontains=Dietary%20Regime</Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Find all biospecimens which have the metadata value “Zea mays” and which are not from the project “AGRI_HYoon_EPAN14_0188”
                                            <br />
                                            <Link to="/query/api/lims/substance/detail/substance_type=Biospecimen&metadata__value__iexact=Zea%20mays&project__sg_project_id__not_exact=AGRI_HYoon_EPAN14_0188/">https://kbapi.secondgenome.com/api/lims/substance/detail/ ?substance_type=Biospecimen &metadata__value__iexact=Zea%20mays &project__sg_project_id__not_exact=AGRI_HYoon_EPAN14_0188</Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Find all biospecimens which have the metadata label “Host disease status” and which do not have the value “Case (disease status)” for that label
                                            <br />
                                            <Link to="/query/api/lims/substance/detail/substance_type=Biospecimen&metadata__label__iexact=Host%20disease%20status&metadata__value__not_iexact=Case%20(disease%20status)/">https://kbapi.secondgenome.com/api/lims/substance/detail/ ?substance_type=Biospecimen &metadata__label__iexact=Host%20disease%20status &metadata__value__not_iexact=Case%20(disease%20status)</Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Find all biospecimens that have the metadata label “Organism” and which have a value of any term under Kingdom Plantae for that label
                                            <br />
                                            1, <Link to="/query/api/ontology/ontology/detail/parents__label__iexact=Kingdom%20Plantae/">https://kbapi.secondgenome.com/api/ontology/ontology/detail/?parents__label__iexact=Kingdom Plantae</Link>
                                            <br />
                                            2, <Link to="/query/api/lims/substance/detail/substance_type=Biospecimen%20&metadata__label__iexact=Organism&metadata__value__in=Family%20Solanaceae%20-%20nightshade,Subkingdom%20Tracheobionta/">https://kbapi.secondgenome.com/api/lims/substance/detail/?substance_type=Biospecimen &metadata__label__iexact=Organism&metadata__value__in=Family Solanaceae - nightshade,Subkingdom Tracheobionta</Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Find all the project IDs which contain the term “Biospecimen” [deliberate misspelling here] anywhere in the metadata
                                            <br />
                                            <Link to="/query/api/projects/project/detail/substance__metadata__label__icontains=Biospecimen/">https://kbapi.secondgenome.com/api/projects/project/detail/ ?substance__metadata__label__icontains=Biospecimen</Link>
                                            <br />
                                            <Link to="/query/api/projects/project/detail/substance__metadata__value__icontains=Biospecimen/">https://kbapi.secondgenome.com/api/projects/project/detail/ ?substance__metadata__value__icontains=Biospecimen</Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Find all biospecimens which contain the term “Crohn’s disease” as either a label or value
                                            <br />
                                            <Link to="/query/api/lims/substance/detail/substance_type=Biospecimen&metadata__label__icontains=Crohn's disease/">https://kbapi.secondgenome.com/api/lims/substance/detail/ ?substance_type=Biospecimen &metadata__label__icontains=Crohn's disease</Link>
                                            <br />
                                            <Link to="/query/api/lims/substance/detail/substance_type=Biospecimen&metadata__value__icontains=Crohn's disease/">https://kbapi.secondgenome.com/api/lims/substance/detail/ ?substance_type=Biospecimen &metadata__value__icontains=Crohn's disease</Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Show the data of a reportcollection
                                            <br />
                                            <Link to="/query/backends/reportcollection/list/1/all=false/">https://kbapi.secondgenome.com/query/backends/reportcollection/list/1/all=false/</Link>
                                            <br />
                                            Change <code>all=true</code> to get all output results.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Show the sum of abundances as well as number of biospecimen that have "Inflammatory bowel disease" as "Disease focus" and have a "Histological status" annotated and contain at least one OTU with species assignment 'distasonis', 'dispar' or 'longum'.
                                            <br />
                                            <Link to="/query/api/lims/abundance/detail/feature__category=OTU&feature__taxonomy_lowest_rank__in=distasonis,dispar,longum&substance__source_substance__source_substance__source_substance__source_substance__metadata__label=Disease%20focus&substance__source_substance__source_substance__source_substance__source_substance__metadata__value=Inflammatory%20bowel%20disease&substance__source_substance__source_substance__source_substance__source_substance__metadata__label=Histological%20status/">https://kbapi.secondgenome.com/api/lims/abundance/detail/
                                            ?feature__category=OTU
                                            &feature__taxonomy_lowest_rank__in=distasonis,dispar,longum
                                            &substance__source_substance__source_substance
                                            __source_substance__source_substance__metadata__label
                                            =Disease%20focus
                                            &substance__source_substance__source_substance
                                            __source_substance__source_substance__metadata__value
                                            =Inflammatory%20bowel%20disease
                                            &substance__source_substance__source_substance
                                            __source_substance__source_substance__metadata__label
                                            =Histological%20status</Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="https://docs.google.com/document/d/1F1LIf3bYdnxRyKGB8_Is27hBMZGKUykpTwoymDZ-72c/">More examples in this document</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Panel>
                    </Col>
                </Row>
            </Grid>
        );
    }
}
export default QueryHome;

// <Col xs={0} md={0}>
//     <Panel header="References">
//         {readme}
//     </Panel>
// </Col>


// <Panel header="Recent queries">
//     dd
// </Panel>
