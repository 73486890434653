import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Settings from '../common/Settings';
import { Row, Col, FormControl, FormGroup, Well, Checkbox } from 'react-bootstrap';

class QueryForm extends Component{
    constructor(props){
        super(props);
        this.state = {
            query: this.props.data_url,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    };

    handleFocus(event) {
        event.target.select();
    };

    handleChange = (event) => {
        this.setState({ query: event.target.value });
    };

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.query !== ""){
            var url = this.state.query;
            console.log(url)
            if (url.split("?").length === 1){
                url = url + '?id__isnull=false';
            }

            var params = url.split("/");
            if (params.length === 8){
                window.location.assign('/query/'+params[3]+'/'+params[4]+'/'+params[5]+'/'+params[6]+'/'+params[7].replace("?","")+'/');
            }
            else{
                alert("Wrong query!");
            }
        }
    }

    handleCheck = (ob)=>{
        var query = this.state.query;

        query = query.replace("&" + ob.target.name+"=false", "");
        query = query.replace(ob.target.name+"=false", "");
        if (this.state.query.indexOf(ob.target.name+"=true") === -1){
            if (query.indexOf("?") === 0){
                query = query + "?" + ob.target.name+"=true";
            }
            else{
                query = query + "&" + ob.target.name+"=true";
            }
        }
        else{
            query = query.replace("&" + ob.target.name+"=true", "");
            query = query.replace(ob.target.name+"=true", "");
        }
        query = query.replace("?&", "?");
        this.setState({query: query});
    }

    handlePressEnter = (e)=>{
        if (e.keyCode === 13) {
            this.handleSubmit(e);
        }
    }

    handleConstructorURL = (e)=>{
        if (e.keyCode === 13) {
            this.handleSubmit(e);
        }
        else{
            var placeholder = e.target.getAttribute("placeholder");
            var base_url = Settings.Host + "/api/lims/substance/simple/?barcode__icontains=";
            if (placeholder === "Search projects via sg_project_id"){
                base_url = Settings.Host + "/api/projects/project/simple/?sg_project_id__icontains=";
            }
            else if (placeholder === "Search substances via Batch ID"){
                base_url = Settings.Host + "/api/lims/substance/simple/?metadata__label__icontains=batch ID&metadata__value__icontains="
            }
            var url = base_url + e.target.value;
            document.getElementById("query-url").value = url;
            this.setState({query: url});
        }
    }

    render(){
        return (
            <Row>
                <Col xs={12} md={12}>
                    <Well bsSize="small">
                        <FormGroup className="form-inline">
                            <FormControl type="url" id="query-url" placeholder="Paste API URL" style={{minWidth: '90%'}} value={this.state.query} onFocus={this.handleFocus} onChange={this.handleChange} onKeyUp={this.handlePressEnter} />
                            {' '}
                            <FormControl type="submit" style={{minWidth: '9%'}} onClick={this.handleSubmit} />
                            <br /><br />
                            <Row>
                                <Col xs={4} md={4}>
                                    <FormControl type="text" placeholder="Search substances via Barcode" style={{minWidth: '100%'}} onKeyUp={this.handleConstructorURL} />
                                </Col>
                                <Col xs={4} md={4}>
                                    <FormControl type="text" placeholder="Search substances via Batch ID" style={{minWidth: '100%'}} onKeyUp={this.handleConstructorURL} />
                                </Col>
                                <Col xs={4} md={4}>
                                    <FormControl type="text" placeholder="Search projects via sg_project_id" style={{minWidth: '100%'}} onKeyUp={this.handleConstructorURL} />
                                </Col>
                            </Row>
                            {window.location.href.indexOf("backends/report/") !== -1?
                                <div>
                                    <br />
                                    <h4>Build your report:</h4>
                                    <Checkbox name="all" onChange={this.handleCheck} checked={this.state.query.indexOf("all=true") === -1 ? false : true}> {' '}<b>all=true</b>, include all metadata headers, all=false will exclude the columns that are identical across all samples.</Checkbox><br />
                                    <Checkbox name="monsanto" onChange={this.handleCheck} checked={this.state.query.indexOf("monsanto=true") === -1 ? false : true}> {' '}<b>monsanto=true</b>, to generate the specific table headers for Monsanto.</Checkbox><br />
                                    <Checkbox name="monsanto_ontology" onChange={this.handleCheck} checked={this.state.query.indexOf("monsanto_ontology=true") === -1 ? false : true}> {' '}<b>monsanto_ontology=true</b>, same to monsanto=true, but replace ontology term with ontology lineage. Be patient, this process is really slow.</Checkbox><br />
                                    <Checkbox name="monsanto_biosources" onChange={this.handleCheck} checked={this.state.query.indexOf("monsanto_biosources=true") === -1 ? false : true}> {' '}<b>monsanto_biosources=true</b>, provide human readable Bioosurce to Bioassay Hit table.</Checkbox><br />
                                    <Checkbox name="monsanto_summary" onChange={this.handleCheck} checked={this.state.query.indexOf("monsanto_summary=true") === -1 ? false : true}> {' '}<b>monsanto_summary=true</b>, project summary table for monsanto.</Checkbox><br />
                                    <Checkbox name="lineage" onChange={this.handleCheck} checked={this.state.query.indexOf("lineage=true") === -1 ? false : true}> {' '}<b>lineage=true</b>, to include the parent ontology terms for metadata labels.</Checkbox><br />
                                    <Checkbox name="inheritchild" onChange={this.handleCheck} checked={this.state.query.indexOf("inheritchild=true") === -1 ? false : true}> {' '}<b>inheritchild=true</b>, to include inherit child term. This will override lineage=true</Checkbox><br />
                                    <Checkbox name="mappingfile" onChange={this.handleCheck} checked={this.state.query.indexOf("mappingfile=true") === -1 ? false : true}> {' '}<b>mappingfile=true</b>, to generate global mapping files. Availabe for report collection of OmicFileSet and AbundanceSet only!</Checkbox><br />
                                    <Checkbox name="stage_status" onChange={this.handleCheck} checked={this.state.query.indexOf("stage_status=true") === -1 ? false : true}> {' '}<b>stage_status=true</b>, to check the status of staging, for project only!</Checkbox><br />
                                    <Checkbox name="include_substance_type" onChange={this.handleCheck} checked={this.state.query.indexOf("include_substance_type=true") === -1 ? false : true}> {' '}<b>include_substance_type=true</b>, to include substance types in Metadata columns' names.</Checkbox>
                                </div>
                            : null}
                        </FormGroup>
                    </Well>
                </Col>
            </Row>
        );
    }
}

QueryForm.propTypes = {
    data_url: PropTypes.string,
}

export default QueryForm;
