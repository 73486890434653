import React, {Component} from 'react';
import {Grid, Row, Col, Panel, FormGroup, Button, FormControl} from 'react-bootstrap';
import axios from 'axios';
import * as Settings from '../common/Settings';
import cookie from 'react-cookies';

class Login extends Component{
    constructor(props){
        super(props);
        this.state = {
            email: "",
            password: "",
            button:"Submit",
            throttling: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10), // name of throttling_name
        };
    }

    componentWillMount(){
        if (cookie.load('t',{ path: '/' })){
            this.setState({throttling: cookie.load('t',{ path: '/' })})
        }
        else{
            cookie.save('t',this.state.throttling,{path: '/'});
        }
    }

    handleChange = (event) => {
        if (event.target.attributes.getNamedItem('type').value === "email"){
            this.setState({email: event.target.value});
        }
        else if (event.target.attributes.getNamedItem('type').value === "password"){
            this.setState({password: event.target.value});
        }
    }

    handleSubmit = (event) => {
        if (this.state.email !== "" && this.state.password !== ""){
            var Host = Settings.Host;
            var email = this.state.email;
            this.setState({button: "Submitting..."});
            axios.post(Host + '/api-token-auth/',
                {
                    username: this.state.email,
                    password: this.state.password
                },
                {
                    headers: {
                        "X-Forwarded-For":email,
                        "HTTP_X_FORWARDED_FOR":email
                    }
                }
            )
            .then(function (response) {
                //console.log(response);
                cookie.save('token',response.data.token,{path: '/', maxAge: 86400});
                cookie.save('email',email,{path: '/', maxAge: 86400});
                // localStorage.setItem('token', response.data.token);
                // localStorage.setItem('email', email);
                axios.defaults.headers.common['Authorization'] = "Token " + response.data.token;
                console.log(response.data.password_expired);
                if (response.data.password_expired) {
                    alert("Error: Your password has expired. Please set a new password in the next window.");
                    window.location.assign('/changepassword');
                }
                else {
                    window.location.assign('/');
                }
            })
            .catch(function (error) {
                if (error.message === "Request failed with status code 400"){
                    alert("Error: wrong email or password! Your account will be locked for next 24 hours if consecutivly failed 5 times.");
                }
                else if (error.message === "Request failed with status code 429"){
                    alert("Error: Too many request; Your account is locked for next 24 hours.");
                }
                console.log(error.message);
                cookie.remove("token", { path: '/' });
                cookie.remove("email", { path: '/' });
                //localStorage.clear();
            });
        }
        else{
            alert("Please provide email or password!");
        }
    }

    render (){
        return (
            <Grid>
                <Row>
                    <Col xs={0} md={4}></Col>
                    <Col xs={12} md={4}>
                        <Panel header="Login">
                            <form>
                                <FormGroup controlId="formBasicText">
                                    <FormControl
                                    type="email"
                                    placeholder="Email"
                                    onChange={this.handleChange}
                                    />
                                </FormGroup>
                                <FormGroup controlId="formBasicPassword">
                                    <FormControl
                                    type="password"
                                    placeholder="Password"
                                    onChange={this.handleChange}
                                    />
                                </FormGroup>
                                <Button type="button" onClick={this.handleSubmit}>{this.state.button}</Button>
                            </form>
                            <br />
                            Please make sure you have VPN connected. Contact help@truadvantage.com if you have any issues about VPN.
                        </Panel>
                    </Col>
                    <Col xs={0} md={4}></Col>
                </Row>
            </Grid>
        );
    }
}
export default Login;
