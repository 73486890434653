import React from 'react';

const KBDescriptionField = ({id, description}) => {
    return <div
        id={id}
        className="description"
        style={{color: 'grey', marginBottom: '2em'}}
        dangerouslySetInnerHTML={{__html: description}}
    />;
};

export default KBDescriptionField;
