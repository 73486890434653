import React, { Component } from 'react';
import { Grid, Row, Col, Panel } from 'react-bootstrap';
import Substance from '../visualization/Substance';

class Visualization extends Component{
    constructor(props){
        super(props);
        this.state = {

        }
    }

    componentWillMount(){
        // axios.get(Settings.Host + "/api/projects/project/key/?research_area_specific__in=Atopic dermatitis,Atopy,Allergic hypersensitivity disease,Skin disease&limit=1000",{
        //     headers: {
        //         "Authorization":"Token " + cookie.load('token',{ path: '/' })
        //     }
        // })
        //     .then(res =>{
        //         //res.data.ui = this.handleForeignKeyWidget(res.data.ui);
        //         this.setState({ projects: res.data.results });
        // });
    }

    componentDidMount(){
    }

    render() {
        return (
            <Grid fluid>
                <Row>
                    <Col xs={12} md={12}>
                        <Panel header="Substances and Projects">
                            <Substance />
                        </Panel>
                    </Col>
                </Row>
            </Grid>
        );
    }
}
export default Visualization;
