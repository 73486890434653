import cookie from 'react-cookies';

export var APIUrl2ReactLink =function(url){
    if (url !== "" && url !== null){
        url = url.replace("?format=json&","").split(": ")[0];
        var params = url.split("/");
        if (params.length >= 8){
            if (params[7].indexOf("=") !== -1){
                var link = '/query/'+params[3]+'/'+params[4]+'/'+params[5]+'/'+params[6]+'/'+params[7].replace("?","")+'/';
            }
            else{
                link = '/detail/'+params[3]+'/'+params[4]+'/'+params[5]+'/'+params[6]+'/'+params[7]+'/';
            }
            return link;
        }
        else{
            return url;
        }
    }
    else{
        return url;
    }
}

export var loggedIn = function(){
    if (cookie.load('token',{ path: '/' }) === undefined){
        return false;
    }
    else{
        return true;
    }
}
