/*
https://stackoverflow.com/questions/39036457/react-create-constants-file
export const Host="http://127.0.0.1:8000"
import * as Settings from './Settings'
use it as Settings.Host
*/

import {AutoBuildDomainName, AutoBuildEnable} from './BuildConf';

const ENV_LOCAL = 'local';
const ENV_TEST = 'test';
const ENV_DEV = 'dev';
const ENV_PROD = 'product';

export const env = ENV_LOCAL; // dev, test, local, product

export const getHost = () => {

    if (AutoBuildEnable) {
        return 'https://' + AutoBuildDomainName;
    }

    if (env === ENV_LOCAL) {
        return 'http://127.0.0.1:8000';
    } else if (env === ENV_TEST) {
        return 'https://kbtestapi.secondgenome.com';
    } else if (env === ENV_DEV) {
        return 'https://kbapi.dev.secondgenome.com';
    } else if (env === ENV_PROD){
        return 'https://kbapi.secondgenome.com'; //10.1.1.243
    } else {
        throw 'Environment should be one of local, test, dev, or product';
    }
};

export const Host = getHost();

export const getDomain = () => {

    if (AutoBuildEnable) {
        return AutoBuildDomainName;
    }

    if (env === ENV_LOCAL) {
        return '127.0.0.1:8000';
    } else if (env === ENV_DEV) {
        return 'kb.dev.secondgenome.com';
    } else {
        return 'secondgenome.com';
    }
};

export const Domain = getDomain();

export const Loading = 'Loading...';