import React from 'react';
import * as Settings from '../common/Settings';
import cookie from 'react-cookies';
import ResultsForm from './ResultsForm';
import {post} from '../../request';

const prepareSchema = () => {
    const billing_codes_url = `${Settings.Host}/backends/billing_codes/`;
    const request = new XMLHttpRequest();
    request.open('GET', billing_codes_url, false);
    request.setRequestHeader('Authorization', 'Token ' + cookie.load('token', {path: '/'}));
    request.setRequestHeader('Content-Type', 'application/json');
    request.send(null);
    const billing_codes = JSON.parse(request.responseText);
    return {
        schema: {
            type: 'object',
            title: 'Submit a staging job',
            description: `
                Use this interface to submit staging job to download fastq files from NCBI for a project. 
                Once submitted, you will receive a email about your job submission, 
                and another email about the status of the jobs.
            `,
            required: ['project', 'bill'],
            properties: {
                project: {
                    title: 'Project',
                    description: 'The sg_project_id',
                    type: 'string'
                },
                is_paired: {
                    title: 'Is Paired',
                    description: `
                        Are the files paired end? 
                        If all are paired end, leave the box checked.  
                        If all are single end, uncheck the box.  
                        If the project being staged is a mix of single and paired end data, 
                        or if it is a NextSeq dataset, 
                        make sure that every OFS substance in the project has the metadata label 
                        “Expected file number” with a numeric value.  
                        If this metadata is present it will override the checkbox. 
                        If this metadata is not present for mixed projects, 
                        please add the metadata label and values before submitting this project for staging.
                    `,
                    type: 'boolean',
                    default: true
                },
                service_bill: {
                    title: 'Billing Project',
                    description: 'Select a billing project',
                    type: 'string',
                    enum: billing_codes['billing-code-list'],
                    enumNames: billing_codes['billing-code-names'],
                    default: billing_codes['billing-code-list'][0],
                },
                overwrite: {
                    title: 'Overwrite',
                    description: `
                        Would you like to overwrite already staged files?
                    `,
                    type: 'boolean',
                    default: false
                },
            }
        },
        uiSchema: {},
        formData: {
            bill: 'MI'
        }
    };
};

const Staging = ({history}) => {
    const form = prepareSchema();
    const url = `${Settings.Host}/backends/batch/`;
    const job_definition = 'knowledgebase-stage';
    const [result, setResult] = React.useState({});
    const [disabled, setDisabled] = React.useState(false);

    const handleSubmit = data => {
        setDisabled(true);
        let parameters = `stage_project,${
            data.formData.project
        }+--queue-${data.formData.service_bill}+--email-${cookie.load('email', {path: '/'})}`;
        if (data.formData['is_paired'] === false) {
            parameters = `${parameters}+--single`;
        }
        if (data.formData['is_test'] === true) {
            parameters = `${parameters}+--test`;
        }
        if (data.formData['overwrite'] === true) {
            parameters = `${parameters}+--overwrite`;
        }

        if (Settings.env === 'dev') {// both produciton and local going to use production domain
            parameters = `${parameters}+--baseurl-${Settings.Host}`;
        }
        post({
            url,
            data: {
                email: cookie.load('email', {path: '/'}),
                parameters,
                job_definition
            },
            history,
            setResult,
            setDisabled
        });
    };

    return (
        <ResultsForm {...{form, disabled, result, handleSubmit}} />
    );
};

export default Staging;
