import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
require('react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css');
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

import './index.css';

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById('root')
);
//registerServiceWorker();
