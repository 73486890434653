import React from 'react';
import * as Settings from '../common/Settings';
import cookie from 'react-cookies';
import ResultsForm from './ResultsForm';
import {post} from '../../request';

const prepareSchema = () => {
    return {
        schema: {
            type: 'object',
            title: 'Submit Lab Assays',
            description: `
                Please make sure you have reviewed the submission instructions 
                and confirm that the files meets the specifications listed. 
                Instructions can be viewed here: 
                <a 
                    href='https://docs.google.com/document/d/1fzODyAP6y2r-9ovE6TqtUkPURpF1IuEWSUDj0Ovl2rE/edit' 
                    target='_blank'
                >
                        https://docs.google.com/document/d/1fzODyAP6y2r-9ovE6TqtUkPURpF1IuEWSUDj0Ovl2rE/edit
                </a>
            `,
            required: [
                'before_start', 'ownership', 'Billing_code', 'Labii_experiment_ID',
                'Biospecimen_dtcollected', 'Method_type', 'Assay_type',
                'Organism', 'HELA_OUT_OF_STD_ADJUSTMENT_METHOD', 'HELA_NORMALIZATION_METHOD',
                'Effect_size_type', 'files'
            ],
            properties: {
                before_start: {// do not pass
                    title: 'I confirm',
                    description: `
                        I have reviewed the submission instructions 
                        and confirm that my files meets the specifications listed.
                    `,
                    type: 'boolean',
                    default: false
                },
                ownership: { // do not pass, check with others
                    title: 'Who owns this data?',
                    description: `
                        For Mucosal Immunity data, please select Gilead. 
                        For IO data, please select Second Genome. 
                        For all others, please consult with Project Leads for clarifications if needed.
                    `,
                    type: 'string',
                    enum: ['Second Genome', 'Gilead', 'Test'],
                    enumNames: ['Second Genome', 'Gilead', 'Test'],
                    default: 'Second Genome',
                },
                Billing_code: {
                    title: 'Billing Code',
                    description: 'Select a billing code',
                    type: 'string',
                    enum: ['BD', 'GILDBM', 'IO', 'M3', 'MI', 'SG-2-0776', 'IOBM', 'FilgoNR', 'ARENA'],
                    enumNames: ['Exploratoy', 'Gilead SELECTION Biomarker Research Program (BRP)', 'SG-3-06686', 'M3', 'MI', 'SG-5-00455', 'IOBM', 'FilgoNR', "Arena CULTIVATE Biomarker Program"],
                    default: 'MI',
                },
                Labii_experiment_ID: {
                    title: 'Labii experiment ID',
                    description: 'Labii experiment ID. Example: SGEP998 etc',
                    type: 'string'
                },
                Biospecimen_dtcollected: {
                    title: 'Biospecimen date collected',
                    description: 'Date that readouts were collected in a format yyyy-mm-dd. Example: 2019-07-31',
                    type: 'string',
                },
                Method_type: {
                    title: 'Method type',
                    description: 'What kind of data is this?',
                    type: 'string',
                    enum: ['Meso Scale Discovery', 'CRISPER'],
                    enumNames: [
                        'Meso Scale Discovery', 'CRISPR Meso Scale Discovery'
                    ],
                    default: 'Meso Scale Discovery'
                },
                Assay_type: {
                    title: 'Assay type',
                    description: 'Name of the assay?',
                    type: 'string',
                    enum: [
                        'T cell activation assay', 'TGFb rescue assay', 'Monocyte assay',
                        'Macrophage polarization assay', 'Unstimulated dendritic cell assay',
                        'LPS-stimulated dendritic cell assay', 'CEFT assay', 'Dendritic cell assay',
                        'M1 macrophage assay', 'Treg induction assay', 'Th17 assay'
                    ],
                    enumNames: [
                        'T cell activation assay', 'TGFb rescue assay', 'Monocyte assay',
                        'Macrophage polarization assay', 'Unstimulated dendritic cell assay',
                        'LPS-stimulated dendritic cell assay', 'CEFT assay', 'Dendritic cell assay',
                        'M1 macrophage assay', 'Treg induction assay', 'Th17 assay'
                    ],
                    default: 'T cell activation assay'
                },
                Organism: {
                    title: 'Organism',
                    description: 'What host cells used?',
                    type: 'string',
                    enum: ['Homo sapiens', 'Mus musculus'],
                    enumNames: ['Homo sapiens', 'Mus musculus'],
                    default: 'Homo sapiens'
                },
                HELA_OUT_OF_STD_ADJUSTMENT_METHOD: {
                    title: 'HELA OUT OF STD ADJUSTMENT METHOD',
                    description: 'How do you treat readouts out of standard curve?',
                    type: 'string',
                    enum: ['Std max min'],
                    enumNames: ['Std max min'],
                    default: 'Std max min'
                },
                HELA_NORMALIZATION_METHOD: {
                    title: 'HELA NORMALIZATION METHOD',
                    description: 'How do you want to normalize readouts?',
                    type: 'string',
                    enum: ['NC PC proportion', 'PC proportion', 'NC subtraction', 'No normalization'],
                    enumNames: ['NC PC proportion', 'PC proportion', 'NC subtraction', 'No normalization'],
                    default: 'NC PC proportion'
                },
                Effect_size_type: {
                    title: 'Effect size type',
                    description: 'Select NA for CRISPR.',
                    type: 'string',
                    enum: ['log2FoldChange', 'FoldChange'],
                    enumNames: ['log2FoldChange', 'FoldChange'],
                    default: 'log2FoldChange'
                },
                Treatment_group: {
                    title: 'Treatment group',
                    description: 'Enter the assay observation in order of the treatment groups.',
                    type: 'array',
                    items: {
                        type: 'object',
                        required: ['Assay_observation'],
                        properties: {
                            Assay_observation: {
                                title: 'Assay observation',
                                description: '',
                                type: 'string',
                            },
                        },
                    }
                },
                /*    //temporarily disabling this option
                     batch_analysis: { // check others
                         title: "Should perform batch analysis",
                         description: "You must analyze all files uploaded together or all pairs independently. Check the box to analyze multiple plates in a single batched analysis. Leave unchecked to analyze each plate independently.",
                         type:"boolean",
                         default: false
                     },*/
                files: { //  check the name with others
                    type: 'array',
                    title: 'Files',
                    minItems: 1,
                    items: {
                        type: 'object',
                        required: ['HELA_INPUT_FILEPATH', 'HELA_PROTEIN_MAPPING_FILEPATH'],
                        properties: {
                            HELA_INPUT_FILEPATH: {
                                title: 'HELA INPUT FILE',
                                description: 'Raw data file from the machine. It must be an Excel file.',
                                type: 'string',
                                format: 'data-url',
                            },
                            HELA_PROTEIN_MAPPING_FILEPATH: {
                                title: 'HELA PROTEIN MAPPING FILE',
                                description: 'It has to be a CSV file',
                                type: 'string',
                                format: 'data-url',
                            },
                        },
                    }
                },
            }
        },
        uiSchema: {},
        formData: {}
    };
};

const PAW = ({history}) => {
    const form = prepareSchema();
    const url = `${Settings.Host}/backends/hela/`;
    const [result, setResult] = React.useState({});
    const [disabled, setDisabled] = React.useState(false);

    const handleSubmit = (data) => {
        setDisabled(true);
        data.formData['email'] = cookie.load('email', {path: '/'});
        data.formData['batch_analysis'] = false;
        post({url, data: data.formData, history, setResult, setDisabled});
    };

    return (
        <ResultsForm {...{form, disabled, result, handleSubmit}} />
    );
};

export default PAW;
