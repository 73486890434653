import React from 'react';
import FetchData from '../common/FetchData';
import * as Settings from '../common/Settings';
import {Link} from 'react-router-dom';
import { Table, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

class TableList extends FetchData{
    render (){
        if (this.state.loading) {
            return <div>{Settings.Loading}</div>
        }
        else{
            return (
                <Table hover>
                    <tbody>
                        {this.state.data.results.map(
                            table =>
                            <tr key={table.id}>
                                <td>
                                    <Link to={`/query/api/${table.app}/${table.table}/simple/id__isnull=false/`}>{table.app} - {table.table}</Link>
                                </td>
                                <td>
                                    <Link to={`/query/api/${table.app}/${table.table}/detail/id__isnull=false/`}>detail view</Link>
                                </td>
                                <td>
                                    {table.can_add ?
                                        <LinkContainer to={`/form/${table.app}/${table.table === "project" ? "projectcustomer" : table.table}/detail/0/`}>
                                            <Button bsStyle="info" bsSize="xsmall">Add</Button>
                                        </LinkContainer>
                                    : null}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            );
        }
    }
}
export default TableList;
