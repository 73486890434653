import React from 'react';
import PropTypes from 'prop-types';
import FetchData from '../common/FetchData';
import * as Settings from '../common/Settings'
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Button, ButtonGroup } from 'react-bootstrap';
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import { LinkContainer } from 'react-router-bootstrap';
import { CSVLink } from "react-csv";
import Cookie from 'js-cookie';

var APIUrl2ReactLink = require('../common/util.js').APIUrl2ReactLink;

class ListViewData extends FetchData{
    constructor(props) {
        super(props);
    }

    componentWillMount(){
        // predefine the table style
        this.setState({ table_style: "auto" });
    }

    handleToggleButtonClick = (onClick) => {
        // Custom your onClick event here,
        // it's not necessary to implement this function if you have no any process before onClick
        //console.log('This is my custom function for InserButton click event');
        if (this.state.table_style === "auto"){
            this.setState({ table_style: "100%" });
            document.getElementsByTagName("table")[0].style.width = this.state.table_style;
            document.getElementsByTagName("table")[0].style.tableLayout = "fixed";
        }
        else{
            this.setState({ table_style: "auto" });
            document.getElementsByTagName("table")[0].style.width="";
            document.getElementsByTagName("table")[0].style.tableLayout = "";
        }
    
        // update col width
        if (this.state.table_style === "auto"){
            for (var i=0; i<document.getElementsByTagName("table")[0].getElementsByTagName("th").length; i++){
                var w1 = document.getElementsByTagName("table")[0].getElementsByTagName("tr")[0].getElementsByTagName("th")[i].offsetWidth;
                var w2 = document.getElementsByTagName("table")[0].getElementsByTagName("tr")[1].getElementsByTagName("td")[i].offsetWidth;
                var w = w1;
                if (w < w2){
                    w = w2;
                }
                if (w > 300){
                    w = 300;
                }

                document.getElementsByTagName("table")[0].getElementsByTagName("th")[i].style.width = w + "px";
            }

            document.getElementsByTagName("table")[0].style.width="100%";
            document.getElementsByTagName("table")[0].style.overflow="auto";
        }
        else{
            for (i=0; i<document.getElementsByTagName("table")[0].getElementsByTagName("th").length; i++){
                document.getElementsByTagName("table")[0].getElementsByTagName("th")[i].removeAttribute("style");
            }
        }
    }

    handleInsertButtonClick = (onClick) => {
        window.location.assign('/form/'+ this.props.data_url.split('/')[2] + '/' + this.props.data_url.split('/')[3] + '/detail/0/');
    }

    getHeaderColumns(){
        function arrayFormatter(value){
            if (value.length > 0){
                return value.map(function(v,i){
                    if (v && (v.toString().indexOf(Settings.Domain) !== -1)){
                        var link = APIUrl2ReactLink(v);
                        v = <Link to={link}>{v}</Link>;
                        return <div key={`${i}`}>{v}<br /></div>
                    }
                    if (v.constructor === String){
                        return <div key={`${i}`}>{v}<br /></div>
                    }
                    else{
                        return <div key={`${i}`}>{JSON.stringify(v)}<br /></div>
                    }
                });
            }
            else{
                return ""
            }
        }

        function urlFormatter(cell,row){
            if (Array.isArray(cell)){
                return <div>{arrayFormatter(cell)}</div>;
            }
            else{
                if (cell && (cell.toString().indexOf(Settings.Domain) !== -1)){
                    var link = APIUrl2ReactLink(cell);
                    cell = <Link to={link}>{cell}</Link>;
                }
                return cell;
            }
        }
        if (!("results" in this.state.data)){
            return this.state.data;
        }
        else{
            return Object.keys(this.state.data.results[0] || {}).map(function(key,i){
                if (i === 0){
                    return {
                        dataField: key,
                        align: "left",
                        sort: true,
                        filter: textFilter(),
                        formatter: urlFormatter,
                        text: key,
                        style: { textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }
                    }
                }
                else{
                    return {
                        dataField: key,
                        align: "left",
                        sort: true,
                        filter: textFilter(),
                        formatter: urlFormatter,
                        text: key,
                        style: { textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }
                    }
                }
            });
        }
    }

    replaceQuote = ()=>{
        var results = this.state.data.results;
        results.map((r)=>{
            Object.keys(r).map((k)=>{
                if (typeof r[k] === "string"){
                    r[k] = r[k].replace(/"/g,'');
                }
            });
        });
        return results;
    }

    render (){
        if (this.state.loading) {
            return <div>{Settings.Loading}</div>
        }
        else if (this.state.data.results.length === 0){
            return <div>No results.</div>
        }

        var username = Cookie.get('email')
        const { ExportCSVButton } = CSVExport;
        const { SearchBar, ClearSearchButton } = Search;

        console.log('Username is', username)

        return (
        <ToolkitProvider
            keyField="url"
            data={ this.replaceQuote() }
            columns={ this.getHeaderColumns() }
            exportCSV={ {
                fileName: `${this.props.data_url.split('/')[3]}.csv`,
                onlyExportFiltered: true,
                
            } }
            search={ {
                searchFormatted: true,
                delay: 1000,
            } }
        >
        {
            props =>
            (
                <>
                <ButtonGroup>
                    <Button
                        bsStyle="info"
                        onClick={ () => this.handleInsertButtonClick() }
                    >
                        <Glyphicon glyph="plus-sign" />
                        New
                    </Button>

                    <Button 
                        bsStyle="warning" 
                        onClick={ () => this.handleToggleButtonClick() }
                    >
                        <Glyphicon glyph="object-align-left" />
                        Toggle Cell Width
                    </Button>

                    { username.includes('+gilead') !== true && <ExportCSVButton  // exportCSV button is available only for non gilead users
                        { ...props.csvProps } 
                        className="btn-success" 
                        style={{textShadow: 'none'}}>
                        <Glyphicon glyph="export" /> 
                        Export to CSV
                    </ExportCSVButton> }

                    { this.props.data_url.indexOf("substance") !== -1 && <LinkContainer 
                        to={`/form/lims/reportcollection/detail/0/?${this.props.data_url.split("?")[1]}`}
                    >
                    <Button 
                        bsStyle="default"
                    >
                        Create Report Collection
                    </Button>
                    </LinkContainer> }

                    { this.props.data_url.indexOf("mappingfile=true") !== -1 && <CSVLink 
                        data={this.state.data.results} 
                        separator={"\t"} 
                        enclosingCharacter="" 
                        filename={`${this.props.data_url.split('/')[3]}.tsv`} 
                        className="btn btn-default"
                    >
                    Export as Global Mapping
                    </CSVLink>}

                </ButtonGroup>
                
                <SearchBar { ...props.searchProps } style={{ "zIndex": 0 }}/>

                <ClearSearchButton { ...props.searchProps } />
                
                <BootstrapTable 
                    { ...props.baseProps } 
                    pagination={paginationFactory({ sizePerPage: 10, showTotal: true })}
                    striped={true}
                    hover={true}
                    filter={ filterFactory() }
                    wrapperClasses="table-responsive"
                    rowClasses="text-nowrap"
                    rowStyle={ { textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" } }
                    // keyBoardNav={true}  not implemented yet
                />
                </>
            )
        }
        </ToolkitProvider>
        );
    }
}

ListViewData.propTypes = {
    data_url: PropTypes.string,
}

export default ListViewData;
