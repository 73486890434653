import React, { Component } from 'react';
import {Grid, Row, Col, Alert, Button} from 'react-bootstrap';
import Form from "@rjsf/core";
import axios from 'axios';
import * as Settings from '../common/Settings';
import FormMatch from './FormMatch';
import cookie from 'react-cookies';
import KBDescriptionField from '../common/KBDescriptionField';

const log = (type) => console.log.bind(console, type);

const fields = {
    DescriptionField: KBDescriptionField,
};

const no_match_tables = ["labii2kb", "kbetl"]

class FormView extends Component{
    constructor(props){
        super(props);
        this.state = {
            schema: {},
            formData: {}, // used to prefill the form
            uiSchema: {},
            tableApp: this.props.match.params.tableApp,
            tableName: this.props.match.params.tableName,
            viewMethod: this.props.match.params.viewMethod,
            tableID: this.props.match.params.tableID,
            url_detail: Settings.Host + "/api/" + this.props.match.params.tableApp +"/"+ this.props.match.params.tableName + "/detail/" + this.props.match.params.tableID + "/", // detail url to get detail
            url_list: Settings.Host + "/api/" + this.props.match.params.tableApp +"/"+ this.props.match.params.tableName  +"/detail/", // list url to post
            url_match: Settings.Host + "/api/" + this.props.match.params.tableApp +"/"+ this.props.match.params.tableName + "/simple/", // url to get match data
            matchResponse: {count:0,results:[]}, // the final match response
            log: "", // the log data
            status: "Loading schema...", // the status
            disabled: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    prepareData(){
        this.setState({status: "Start downloading data..."});
        // get the form data
        if (this.props.match.params.tableID !== "0"){
            axios.get(this.state.url_detail + "?format=json",{
                headers: {
                    "Authorization":"Token " + cookie.load('token',{ path: '/' })
                }
            })
            .then(res =>{
                this.setState({status: "Data downloaded."});
                // rmove null value
                for (var i in res.data){
                    if (res.data[i] === null){
                        delete res.data[i]
                    }
                }
                this.setState({ formData: res.data });
            })
            .catch(res =>{
                alert("Error: "+ res.response.data.detail);
                if (res.response.status === 401){
                    // localStorage.clear();
                    cookie.remove("token", { path: '/' });
                    cookie.remove("email", { path: '/' });
                    window.location.assign('/');
                }
                this.setState({ data:{"Error":res.response.data.detail} });
                //this.setState({ data:{"Error 403":"Forbidden"} });
                this.setState({ loading: false })
            });
        }
        // get the data from url
        else if (window.location.href.indexOf("?") !== -1 && window.location.href.indexOf("/api/") !== -1 ){
            var tmp = window.location.href.split("?")[1].split("&")[0];
            var field = tmp.split("=")[0];
            var url = tmp.split("=")[1];
            //console.log(url);
            url = url.replace("___","?").split("---").join("=").split(":::").join("&");

            if (url.indexOf("?") === -1){
                url = url + "?format=json&limit=1000";
            }
            else{
                url = url + "&format=json&limit=1000";
            }
            // download the data
            axios.get(Settings.Host + url + "&format=json",{
                headers: {
                    "Authorization":"Token " + cookie.load('token',{ path: '/' })
                }
            })
            .then(res =>{
                this.setState({status: "Data downloaded."});
                if (this.state.schema.properties[field].type === "array"){
                    var d = [];
                    res.data.results.map((item)=>{
                        d.push(item.url);
                    })
                }
                else{
                    d = res.data.results[0].url;
                }
                var form_data = this.state.formData;
                form_data[field] = d;
                this.setState({ formData: form_data });
            })
            .catch(res =>{
                if (res.response){
                    alert("Error: "+ res.response.data.detail);
                    if (res.response.status === 401){
                        // localStorage.clear();
                        cookie.remove("token", { path: '/' });
                        cookie.remove("email", { path: '/' });
                        window.location.assign('/');
                    }
                    this.setState({ data:{"Error":res.response.data.detail} });
                }
                //this.setState({ data:{"Error 403":"Forbidden"} });
                this.setState({ loading: false });
            })
        }
        // get data url url
        else if (window.location.href.indexOf("?") !== -1){
            this.setState({status: "Loaded! The query will be appended: " + window.location.href.split("?")[1]});
            this.setState({url_list: this.state.url_list + "?" + window.location.href.split("?")[1]});
            if (Object.keys(this.state.schema.properties).indexOf("query") !== -1){// preload the query
                 this.setState({
                     formData:{...this.state.formData,
                         query: window.location.href.split("?")[1]
                     }
                 });
            }
        }
        else{
            this.setState({status: "Loaded!"});
        }
    }

    componentDidMount(){
        this.setState({status: "Start downloading schema..."});
        // get the schema data
        var url = Settings.Host + "/backends/schema/" + this.props.match.params.tableName + "/";
        if (this.props.match.params.tableID !== "0"){
            url = url + "?reportcollection=" + this.props.match.params.tableID;
        }
        else if (window.location.href.indexOf("?") !== -1 && window.location.href.indexOf("/reportcollection/") === -1){
            var query = window.location.href.split("?")[1].split("&")[0].split("=")[1];
            if (query.indexOf("___") !== -1){
                query = query.split("___")[1].split("---").join("=").split(":::").join("&");
            }
            url = url + "?" + query;
        }

        axios.get(url,{
                headers: {
                    "Authorization":"Token " + cookie.load('token',{ path: '/' })
                }
            })
            .then(res =>{
                this.setState({status: "Form schema downloaded..."});
                if (res.data.json["title"] === "PCRMethod") {
                    res.data.json["title"] = "Amplicon Method";
                    res.data.json.properties.name.description = res.data.json.properties.name.description.replace("PCR", "Amplicon");
                }
                if (this.state.tableID !== "0"){
                    delete res.data.json.properties["tag"];
                    delete res.data.ui["tag"];
                    delete res.data.json.properties["metadata"];
                    delete res.data.ui["metadata"];
                }
                if (window.location.href.indexOf("/0/") !== -1){
                    res.data.json["title"] = "Add " + res.data.json["title"];
                }
                else{
                    res.data.json["title"] = "Edit " + res.data.json["title"];
                }
                //res.data.ui = this.handleForeignKeyWidget(res.data.ui);
                this.setState({ schema: res.data.json }, ()=>{
                    this.prepareData();
                });
                this.setState({ uiSchema: res.data.ui });
            })
            .catch(res =>{
                alert("Error: "+ res.response.data.detail);
                if (res.response.status === 401){
                    // localStorage.clear();
                    cookie.remove("token", { path: '/' });
                    cookie.remove("email", { path: '/' });
                    window.location.assign('/');
                }
                this.setState({ data:{"Error":res.response.data.detail} });
                //this.setState({ data:{"Error 403":"Forbidden"} });
                this.setState({ loading: false });
            })
    }

    handleSubmit(data) {
        this.setState({disabled: true});
        // updated array value
        for (var i in data.formData){
            if (data.formData[i] !== undefined && data.formData[i].constructor === Array){
                for (var j in data.formData[i]){
                    if (data.formData[i][j].constructor !== Object){
                        if (data.formData[i][j].indexOf('http') !== -1){
                            data.formData[i][j] = data.formData[i][j].replace(":8000","");
                            data.formData[i][j] = data.formData[i][j].split(":")[1].split("/")[data.formData[i][j].split(":")[1].split("/").length - 2]
                        }
                    }
                }
            }
        }
        if (this.state.tableID !== "0"){
            for (var i in data.formData) {
                if (data.formData[i] === undefined) {
                    data.formData[i] = null;
                }
            }
            axios.put(this.state.url_detail,
                JSON.stringify(data.formData),{
                headers: {
                    "Authorization":"Token " + cookie.load('token',{ path: '/' }),
                    "Content-Type": "application/json"
                }
            })
            .then(res =>{
                if (this.state.tableName === "projectcustomer"){
                    window.location.assign("/detail/api/" + this.state.tableApp + "/project/detail/"+res.data.id+"/");
                }
                else{
                    window.location.assign("/detail/api/" + this.state.tableApp + "/" + this.state.tableName + "/detail/"+res.data.id+"/");
                }
                //window.location.assign("/detail/api/" + this.state.tableApp + "/" + this.state.tableName + "/" + this.state.viewMethod + "/" + this.state.tableID + "/");
            });
        }
        else{
            axios.post(this.state.url_list,
                JSON.stringify(data.formData),{
                headers: {
                    "Authorization":"Token " + cookie.load('token',{ path: '/' }),
                    "Content-Type": "application/json"
                }
            })
            .then(res =>{
                if (this.state.tableName === "projectcustomer"){
                    window.location.assign("/detail/api/" + this.state.tableApp + "/project/detail/"+res.data.id+"/");
                }
                else if (this.state.tableName === "labii2kb" || this.state.tableName === "kbetl"){
                    this.setState({log:res.data.log});
                }
                else{
                    window.location.assign("/detail/api/" + this.state.tableApp + "/" + this.state.tableName + "/detail/"+res.data.id+"/");
                }
                //window.location.assign("/query/api/" + this.state.tableApp + "/" + this.state.tableName + "/" + this.state.viewMethod + "/id_isnull=false");
            })
            .catch(res =>{
                alert(JSON.stringify(res.response.data));
                console.log(res.response.data);
            })
            .finally(() => this.setState({display:false}));
        }
    }

    handleChange = (data) => {
        this.setState({formData:data.formData});
        console.log(data);
        const queryParamDisableSelenium = new URLSearchParams(this.props.location.search).get("selenium")
        if (!queryParamDisableSelenium && no_match_tables.indexOf(this.state.tableName) === -1) {
            this.handleCheck();
        }
    }

    handleCheck = ()=>{
        // build the query url
        var query = "";
        Object.keys(this.state.formData).map((key, i)=>{
            if (this.state.formData[key] !== undefined && key !== "url" && this.state.formData[key] !== ""){
                if (key in this.state.schema.properties){
                    if (! Array.isArray(this.state.formData[key])){
                        console.log(this.state.formData[key])
                        if (["publication"].indexOf(key) !== -1){

                        }
                        else if (["is_locked"].indexOf(key) !== -1){
                            query = query + "&" + key + "=" + this.state.formData[key];
                        }
                        else if (["user"].indexOf(key) !== -1){
                            query = query + "&" + key + "__id=" + this.state.formData[key].split("/")[7];
                        }
                        else if (this.state.formData[key].toString().indexOf("http://kbapi") !== -1){
                            query = query + "&" + key + "__id=" + this.state.formData[key].split("/")[7];
                        }
                        else{
                            query = query + "&" + key + "__icontains=" + this.state.formData[key];
                        }
                    }
                }
            }
            return "";
        });
        query = query.replace("&","");
        if (query !== ""){
            query = "?" + query;
        }
        console.log(query)
        // get the matched data
        axios.get(this.state.url_list.split("?")[0] + query,{
            headers: {
                "Authorization":"Token " + cookie.load('token',{ path: '/' })
            }
        })
            .then(res =>{
                console.log(res.data);
                this.setState({ matchResponse: res.data });
        })
            .catch(res =>{
                //alert("Error: "+ JSON.stringify(res.response.data.detail));
                console.log(JSON.stringify(res.response.data.detail));
                if (res.response.status === 401){
                    cookie.remove("token", { path: '/' });
                    cookie.remove("email", { path: '/' });
                    window.location.assign('/');
                }
                this.setState({ data:{"Error":res.response.data.detail} });
                //this.setState({ data:{"Error 403":"Forbidden"} });
                this.setState({ loading: false });
            });
    }

    handleAutoFill = (ob)=>{
        // get the form data
        var url = Settings.Host + "/api/" + this.props.match.params.tableApp +"/"+ this.props.match.params.tableName + "/" + this.props.match.params.viewMethod + "/" + ob.target.name + "/";
        url = url.replace("projectcustomer","project")
        axios.get(url+ "?format=json",{
            headers: {
                "Authorization":"Token " + cookie.load('token',{ path: '/' })
            }
        })
        .then(res =>{
            // rmove null value
            for (var i in res.data){
                if (res.data[i] === null){
                    delete res.data[i]
                }
            }
            this.setState({ formData: res.data });
        });
    }

    render(){
        return (
            <Grid fluid>
                <Alert bsStyle="warning">{this.state.status}</Alert>
                {this.state.log === "" ?
                <Row>
                    <Col xs={no_match_tables.indexOf(this.state.tableName) !== -1 ? 12 : 8}>
                        <Form schema={this.state.schema}
                            formData={this.state.formData}
                            uiSchema={this.state.uiSchema}
                            onSubmit={this.handleSubmit}
                            onError={log("errors")}
                            onChange={this.handleChange}
                            {...{fields, disabled: this.state.disabled}}
                        >
                            <Button
                                disabled={this.state.disabled}
                                bsStyle="info"
                                type="submit"
                            >
                                Submit
                            </Button>
                        </Form>
                    </Col>
                    <Col xs={no_match_tables.indexOf(this.state.tableName) !== -1 ? 0 : 4}>
                        {no_match_tables.indexOf(this.state.tableName) === -1 ?
                            <FormMatch
                                tableApp={this.state.tableApp}
                                tableName={this.state.tableName}
                                matchResponse={this.state.matchResponse}
                                handleCheck={this.handleCheck}
                                handleAutoFill={this.handleAutoFill}
                            />
                        : null}
                    </Col>
                </Row>
                :
                <Row>
                    <Col xs={12}>
                        <div dangerouslySetInnerHTML={{__html:this.state.log}}></div>
                    </Col>
                </Row>
            }

            </Grid>
        );
    }
}
export default FormView;
