import React from 'react';
import ListViewData from '../list/ListViewData';
import QueryForm from './QueryForm';
import * as Settings from '../common/Settings';

//host, the host ip address and port
//viewMethod
//tableApp
//tableName
//query

// <NavBar data_url="/api/tables/?limit=30" data_fluid />
// <h3>{`Table: ${match.params.tableName}`}</h3>
// <TableViewListItems data_url={`/api/${match.params.tableApp}/${match.params.tableName}/${match.params.viewMethod}/?${match.params.query}`} />
const QueryView = ({match}) => (
    <div>
        <QueryForm data_url={`${Settings.Host}/${match.params.App}/${match.params.tableApp}/${match.params.tableName}/${match.params.viewMethod}/?${match.params.query}`} />
        <ListViewData data_url={`/${match.params.App}/${match.params.tableApp}/${match.params.tableName}/${match.params.viewMethod}/?${match.params.query}`} />
    </div>
);

export default QueryView;

//<ListViewData data_host={`http://${match.params.host}`} data_url={`/${match.params.App}/${match.params.tableApp}/${match.params.tableName}/${match.params.viewMethod}/?${match.params.query}`} table_app={match.params.tableApp} table_name={match.params.tableName} />
