import React from 'react';
import {Grid, Row, Col, Panel, Table } from 'react-bootstrap';


class DashboardGrid extends React.Component{
    constructor(props){
        super(props);
    }

    render (){
        return (
            <Grid fluid>
                <Row>
                    <Col xs={12} md={12}>
                        <Panel header="Substance counts by Type">
                            <Table hover striped>
                                <thead>
                                    <tr>
                                        {
                                        this.props.dashboard_data.substance_counts_by_substance_type.length > 0 ?
                                            Object.keys(this.props.dashboard_data.substance_counts_by_substance_type[0]).map((key) =>{
                                                return(
                                                    <th key={key}>{key}</th>
                                                )
                                            })
                                        :
                                            <div>No data available.</div>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.dashboard_data.substance_counts_by_substance_type.map((item, i)=>{
                                            return(
                                                <tr key={i}>
                                                    {
                                                        Object.keys(item).map((key)=>{
                                                            return key == 'Project ID' ?
                                                                <th>{item[key]}</th>
                                                            :
                                                                <td>{item[key]}</td>
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Panel>

                        <Panel header="Biospecimen counts by Period of Life">
                            <Table hover striped>
                                <thead>
                                    <tr>
                                        {
                                        this.props.dashboard_data.biospecimen_counts_by_period_of_life.length > 0 ?
                                            Object.keys(this.props.dashboard_data.biospecimen_counts_by_period_of_life[0]).map((key) =>{
                                                return(
                                                    <th key={key}>{key}</th>
                                                )
                                            })
                                        :
                                            <div>No data available.</div>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.dashboard_data.biospecimen_counts_by_period_of_life.map((item, i)=>{
                                            return(
                                                <tr key={i}>
                                                    {
                                                        Object.keys(item).map((key)=>{
                                                            return key == 'Project ID' ?
                                                                <th>{item[key]}</th>
                                                            :
                                                                <td>{item[key]}</td>
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Panel>

                        <Panel header="Biospecimen counts by Host Disease Status">
                            <Table hover striped>
                                <thead>
                                    <tr>
                                        {
                                        this.props.dashboard_data.biospecimen_counts_by_host_disease_status.length > 0 ?
                                            Object.keys(this.props.dashboard_data.biospecimen_counts_by_host_disease_status[0]).map((key) =>{
                                                return(
                                                    <th key={key}>{key}</th>
                                                )
                                            })
                                        :
                                            <div>No data available.</div>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.dashboard_data.biospecimen_counts_by_host_disease_status.map((item, i)=>{
                                            return(
                                                <tr key={i}>
                                                    {
                                                        Object.keys(item).map((key)=>{
                                                            return key == 'Project ID' ?
                                                                <th>{item[key]}</th>
                                                            :
                                                                <td>{item[key]}</td>
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Panel>

                        <Panel header="Biospecimen counts by Histological Status">
                            <Table hover striped>
                                <thead>
                                    <tr>
                                        {
                                        this.props.dashboard_data.biospecimen_counts_by_histological_status.length > 0 ?
                                            Object.keys(this.props.dashboard_data.biospecimen_counts_by_histological_status[0]).map((key) =>{
                                                return(
                                                    <th key={key}>{key}</th>
                                                )
                                            })
                                        :
                                            <div>No data available.</div>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.dashboard_data.biospecimen_counts_by_histological_status.map((item, i)=>{
                                            return(
                                                <tr key={i}>
                                                    {
                                                        Object.keys(item).map((key)=>{
                                                            return key == 'Project ID' ?
                                                                <th>{item[key]}</th>
                                                            :
                                                                <td>{item[key]}</td>
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Panel>

                        <Panel header="Biospecimen counts by Host Organism">
                            <Table hover striped>
                                <thead>
                                    <tr>
                                        {
                                        this.props.dashboard_data.biospecimen_counts_by_organism.length > 0 ?
                                            Object.keys(this.props.dashboard_data.biospecimen_counts_by_organism[0]).map((key) =>{
                                                return(
                                                    <th key={key}>{key}</th>
                                                )
                                            })
                                        :
                                            <div>No data available.</div>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.dashboard_data.biospecimen_counts_by_organism.map((item, i)=>{
                                            return(
                                                <tr key={i}>
                                                    {
                                                        Object.keys(item).map((key)=>{
                                                            return key == 'Project ID' ?
                                                                <th>{item[key]}</th>
                                                            :
                                                                <td>{item[key]}</td>
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Panel>

                        <Panel header="Biospecimen counts by Biospecimen Type">
                            <Table hover striped>
                                <thead>
                                    <tr>
                                        {
                                        this.props.dashboard_data.biospecimen_counts_by_type.length > 0 ?
                                            Object.keys(this.props.dashboard_data.biospecimen_counts_by_type[0]).map((key) =>{
                                                return(
                                                    <th key={key}>{key}</th>
                                                )
                                            })
                                        :
                                            <div>No data available.</div>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.dashboard_data.biospecimen_counts_by_type.map((item, i)=>{
                                            return(
                                                <tr key={i}>
                                                    {
                                                        Object.keys(item).map((key)=>{
                                                            return key == 'Project ID' ?
                                                                <th>{item[key]}</th>
                                                            :
                                                                <td>{item[key]}</td>
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Panel>

                        <Panel header="Extract counts by Extract Type">
                            <Table hover striped>
                                <thead>
                                    <tr>
                                        {
                                        this.props.dashboard_data.extract_counts_by_type.length > 0 ?
                                            Object.keys(this.props.dashboard_data.extract_counts_by_type[0]).map((key) =>{
                                                return(
                                                    <th key={key}>{key}</th>
                                                )
                                            })
                                        :
                                            <div>No data available.</div>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.dashboard_data.extract_counts_by_type.map((item, i)=>{
                                            return(
                                                <tr key={i}>
                                                    {
                                                        Object.keys(item).map((key)=>{
                                                            return key == 'Project ID' ?
                                                                <th>{item[key]}</th>
                                                            :
                                                                <td>{item[key]}</td>
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Panel>

                        <Panel header="Library counts by PCR Region">
                            <Table hover striped>
                                <thead>
                                    <tr>
                                        {
                                        this.props.dashboard_data.library_counts_by_region.length > 0 ?
                                            Object.keys(this.props.dashboard_data.library_counts_by_region[0]).map((key) =>{
                                                return(
                                                    <th key={key}>{key}</th>
                                                )
                                            })
                                        :
                                            <div>No data available.</div>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.dashboard_data.library_counts_by_region.map((item, i)=>{
                                            return(
                                                <tr key={i}>
                                                    {
                                                        Object.keys(item).map((key)=>{
                                                            return key == 'Project ID' ?
                                                                <th>{item[key]}</th>
                                                            :
                                                                <td>{item[key]}</td>
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Panel>

                    </Col>
                </Row>
            </Grid>
        );
    }
}

export default DashboardGrid;