import React from 'react';
import {Panel, Label} from 'react-bootstrap';
import * as Settings from '../common/Settings';
import axios from 'axios';
import cookie from 'react-cookies';

class HomePermission extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            groups: ["Loading..."], // array of groups
        }
    }

    componentDidMount(){
        axios.get(Settings.Host + "/api/accounts/user/detail/me/",{
            headers: {
                "Authorization":"Token " + cookie.load('token',{ path: '/' })
            }
        })
            .then(res =>{
                this.setState({ groups: res.data.groups });
        })
    }

    render = () => (
        <Panel header="Permissions">
            Your permission group: <br />
            {this.state.groups.map((g,i)=>{
                return <div key={i}><Label bsStyle="primary">{g}</Label><br /></div>
            })}
            <br />
            <ul>
                <li><b>add</b>, able to add projects</li>
                <li><b>knowledgebase</b>, able to do a lot of things</li>
                <li><b>solutions</b>, able to edit solutions projects</li>
                <li><b>monsanto</b>, able to edit monsanto projects</li>
                <li><b>reportcollection</b>, able to delete reportcollections</li>
            </ul>
            Submit an issue if you do not have the permissions you need or if you need to delete something.
        </Panel>
    )
}

export default HomePermission;
