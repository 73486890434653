import React from 'react';
import * as Settings from '../common/Settings';
import cookie from 'react-cookies';
import ResultsForm from './ResultsForm';
import {post} from '../../request';

const prepareSchema = () => {
    return {
        schema: {
            type: 'object',
            title: 'Stage from S3',
            description: `
                Use this page to submit a kb-staging job for a project 
                where OmicsFileSets are already somewhere in S3 
                and need to be (moved and) organized under sg-inbound-data-drop. 
                Once submitted, you will receive an email to confirm your job submission 
                and a second email about the status of submission (success or failure).
            `,
            required: ['stagingsheet'],
            properties: {
                stagingsheet: {
                    title: 'Staging Sheet',
                    description: `
                        Please copy and paste the data from Staging Sheet. 
                        For example. https://docs.google.com/spreadsheets/d/1Jo5cAvjF4ewySudNwNHHJgEmaJ6dXXcZztWJR4dWdWM/edit?ts=5f20a1e3#gid=322405793
                    `,
                    type: 'string'
                },
                should_submit_job: {
                    title: 'Run constraint checks AND if all passes, submit staging job',
                    description: `
                        Do you want to run 
                        1) the constraint checks only or  
                        2) check and submit if all checks pass? 
                        Please select/mark the box below to do option 2: run check and submit.
                    `,
                    type: 'boolean',
                    default: true
                },
                force_to_write: {
                    title: 'Overwrite',
                    description: `
                        Do you want to re-stage and re-import any OFS 
                        that have already been staged in the KB? 
                        If yes, click ‘Overwrite’ below. 
                        If no, do NOT click the box below. 
                        If no, only OFS' that are new will be linked and any existing OFS' 
                        will be skipped.
                    `,
                    type: 'boolean',
                    default: false
                }
            }
        },
        uiSchema: {
            stagingsheet: {'ui:widget': 'textarea'}
        },
        formData: {}
    };
};

const MoveToS3 = ({history}) => {
    const form = prepareSchema();
    const url = `${Settings.Host}/backends/movetos3/`;
    const [result, setResult] = React.useState({});
    const [disabled, setDisabled] = React.useState(false);

    const handleSubmit = (data) => {
        setDisabled(true);
        data.formData['email'] = cookie.load('email', {path: '/'});
        post({url, data: data.formData, history, setResult, setDisabled});
    };

    return (
        <ResultsForm {...{form, disabled, result, handleSubmit}} />
    );

};

export default MoveToS3;
